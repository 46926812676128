import {
  App,
  ConfigProvider,
  Input,
  message,
  Radio,
  Slider,
  theme,
} from 'antd';
import React, { useState } from 'react';
import { postGenerativeFill, postUploadImage } from '../../apis/adobe/index.js';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import {
  PackageAiFormItem,
  PackageAiFormItemCollapse,
} from '../../components/form.jsx';
import { useAdobeFireflyToken } from '../../utils/useAdobeFireflyToken.js';
import Editor from './editor.jsx';
import style from './index.module.scss';
import Tool from './tool.jsx';

const { TextArea } = Input;

function PackageAiView() {
  const context = JSON.parse(
    sessionStorage.getItem('packageAiContext') ?? '{}'
  );
  const url = context.base.url;
  const id = context.base.id;

  const [loading, setLoading] = useState(false);
  const [brushScale, setBrushScale] = useState(1);
  const [prompt, setPrompt] = useState();
  const [redrawStrength, setRedrawStrength] = useState(1);
  const [scale, setScale] = useState(1);
  const [clearCount, setClearCount] = useState(0);
  const [path, setPath] = useState([]);
  const [drawRef, setDrawRef] = useState(null);
  useAdobeFireflyToken();

  function onScaleChange(e) {
    setBrushScale(e.target.value);
  }

  function onPromptChange(e) {
    setPrompt(e.target.value);
  }

  async function onGenerate() {
    if (!drawRef) return;
    if (prompt && prompt.length > 1024) {
      message.error('Prompt is too long, max length is 1024');
      return;
    }
    if (loading) return;
    setLoading(true);
    try {
      const drawUrl = drawRef.toDataURL();
      const blob = await fetch(drawUrl).then((res) => res.blob());
      const { status: s1, data: d1 } = await postUploadImage(
        blob.arrayBuffer,
        blob.type
      );
      if (s1 !== 200) {
        message.error('Upload mask fail');
        console.error('upload mask fail', s1, d1);
        return;
      }
      if (!d1.images?.length) {
        message.error('Upload mask fail');
        console.error('upload mask fail', d1);
        return;
      }
      const { status: s2, data: d2 } = await postGenerativeFill({
        prompt: prompt,
        size: 'square',
        image: { id: id },
        mask: { id: d1.images[0].id },
      });
      if (s2 !== 200) {
        message.error('Generate image fail');
        console.error('generate image fail', s2, d2);
        return;
      }
      if (!d2.images?.length) {
        message.error('Generate image fail');
        console.error('generate image fail', d2);
        return;
      }
      sessionStorage.setItem(
        'packageAiContext',
        JSON.stringify({
          ...context,
          edit: {
            path,
            prompt,
            redrawStrength,
            maskId: d1.images[0].id,
            imageId: d2.images[0].image.id,
            imageUrl: d2.images[0].image.presignedUrl,
          },
        })
      );
      window.location.href = '/package-ai-generation3';
    } catch (err) {
      console.error('generate image error', err);
      message.error('Generate image error');
    }
    setLoading(false);
  }

  if (!url || !id) {
    message.error('Please select a package first');
    window.location.href = '/package-ai-generation';
    return;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={2}>
          <div className={style.box}>
            <Box className={style.box1} loading={loading}>
              <Editor
                url={url}
                scale={scale}
                setScale={setScale}
                brushScale={brushScale}
                clearCount={clearCount}
                data={path}
                setData={setPath}
                setDrawRef={setDrawRef}
              ></Editor>
              <Tool
                scale={scale}
                setScale={setScale}
                onClear={() => setClearCount(clearCount + 1)}
              ></Tool>
              <div className={style.info1}>
                * Due to privacy legislation all insights & photos / videos can
                only be used internally,
                <br />
                and only after verbal consent from the audience. Ideally, no
                personal data is
                <br />
                obtained and any personal data obtained anonymized.*
              </div>
            </Box>
            <Box
              className={style.box2}
              hasHeader={true}
              title="Edit with Easy AI"
            >
              <div className={style.box2Content}>
                <PackageAiFormItem label="Brush Scale">
                  <Radio.Group value={brushScale} onChange={onScaleChange}>
                    <Radio value={1}>Small</Radio>
                    <Radio value={2}>Medium</Radio>
                    <Radio value={3}>Large</Radio>
                  </Radio.Group>
                </PackageAiFormItem>
                <PackageAiFormItem label="Prompt">
                  <TextArea
                    value={prompt}
                    onChange={onPromptChange}
                    placeholder="Input prompt"
                    style={{ height: 120 }}
                  />
                </PackageAiFormItem>
                <PackageAiFormItemCollapse label="Advance">
                  <div className={style.label}>Redraw strength</div>
                  <Slider
                    min={0}
                    max={25}
                    step={1}
                    value={redrawStrength}
                    onChange={setRedrawStrength}
                  />
                </PackageAiFormItemCollapse>
                <div className={style.btnLine}>
                  <PackageAiBtn2 onClick={onGenerate}>Generate</PackageAiBtn2>
                </div>
              </div>
            </Box>
          </div>
        </Container>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
