import { Button, Form, Select } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilter } from '../../api/index.js';
import LightSvg from '../../assets/icon/icon_light.svg';
import LinkSvg from '../../assets/icon/icon_link.svg';
import titlePng from '../../assets/images/big-title.png';
import {
  getVerticalOptionValue,
  VERTICAL_OPTIONS,
} from '../../components/filter/vertical.js';
import VideoBg from '../../components/video-bg/bg.jsx';
import style from './home.module.scss';

function HomePage() {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [categoryValue, setCategoryValue] = useState(null);
  const [marketValue, setMarket] = useState(null);
  const [brandValue, setBrandValue] = useState(null);
  const [verticalValue, setVerticalValue] = useState(null);

  const [optionsMarket, setOptionsMarket] = useState([]);

  const [verticalValue2, setVerticalValue2] = useState(null);
  const [optionsBrand, setOptionsBrand] = useState([]);
  const onBrandOptions = useCallback(async () => {
    const c = getVerticalOptionValue(verticalValue2);
    const { data: bd } = await getFilter('brand', { category: c });
    if (bd) {
      setOptionsBrand(bd.map((item) => ({ label: item, value: item })));
    }
  }, [verticalValue2]);
  useEffect(() => {
    onBrandOptions().catch((err) => {
      console.error('get brand options error', err);
    });
  }, [onBrandOptions]);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // filter事件
  const handleFilter = (newValue, key) => {
    if (key === 1) {
      setCategoryValue(newValue);
    } else if (key === 2) {
      setMarket(newValue);
    } else if (key === 3) {
      setBrandValue(newValue);
    }
  };

  const getOptionsData = async () => {
    const param = {
      market: [],
      company: [],
      category: [],
      brand: [],
    };
    const { data: md } = await getFilter('market', param);
    if (md) setOptionsMarket(md.map((item) => ({ label: item, value: item })));
    // const { data: bd } = await getFilter('brand', param);
    // if (bd) setOptionsBrand(bd.map((item) => ({ label: item, value: item })));
  };

  useEffect(() => {
    getOptionsData();
  }, []);

  // 弹窗控制
  const [showFilter1, setshowFilter1] = useState(false);
  const [showFilter2, setshowFilter2] = useState(false);
  const showFilter = useCallback(
    (filter) => {
      if (filter === 'competitive') {
        setshowFilter1(true);
      } else if (filter === 'brand') {
        setshowFilter2(true);
      } else {
        console.warn('unkown filter', filter);
      }
    },
    [setshowFilter1, setshowFilter2]
  );
  const closeFilter = useCallback(
    (ev, filter) => {
      ev.stopPropagation();
      if (filter === 'competitive') {
        setshowFilter1(false);
      } else if (filter === 'brand') {
        setshowFilter2(false);
      } else {
        console.warn('unkown filter', filter);
      }
    },
    [setshowFilter1, setshowFilter2]
  );

  // confrim事件
  const onFinishCompetitive = () => {
    navigate(
      `/package-ai-performance/competitive?market=${marketValue}&vertical=${verticalValue}`
    );
  };
  const onFinishBrand = (values) => {
    navigate(`/package-ai-performance/brand?brand=${values.brand}`);
  };

  const onFinishFailed = (err) => {
    console.error('form finish error', err);
  };

  return (
    <div className={style.box1}>
      <div className={style.box11}>
        <h1 className={style.title}>
          <img
            className={style.titleImg}
            src={titlePng}
            alt="Pack AI Performance"
          />
        </h1>
        <div className={style.navContainer}>
          <div className={style.nav} onClick={() => showFilter('competitive')}>
            <div className={style.iconBox}>
              <img className={style.icon} src={LinkSvg} />
            </div>
            <div className={style.navTitle}>Competitive Landscape</div>
            <div className={style.navDesc}>
              Understand how a brand performs compared to competitors in a
              specific market and category
            </div>
            <div className={style.navBg} />
            {showFilter1 && (
              <div className={style.filter}>
                <div
                  className={style.btnClose}
                  onClick={(ev) => closeFilter(ev, 'competitive')}
                />
                <h1 className={style.filterTitle}>Filter</h1>
                <Form
                  form={form}
                  name="company"
                  onFinish={onFinishCompetitive}
                  onFinishFailed={onFinishFailed}
                  className={style.filterContainer}
                >
                  <div className={style.filterFormInner}>
                    <Form.Item
                      label=""
                      name="marketValue"
                      rules={[
                        {
                          required: true,
                          message: 'Please select market!',
                        },
                      ]}
                      className={classNames(style.greenInput)}
                    >
                      <Select
                        placeholder="Market"
                        allowClear
                        onChange={(newValue) => {
                          handleFilter(newValue, 2);
                        }}
                        value={marketValue}
                        options={optionsMarket}
                      />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="verticalValue"
                      rules={[
                        {
                          required: true,
                          message: 'Please select vertical!',
                        },
                      ]}
                      className={classNames(style.greenInput)}
                    >
                      <Select
                        placeholder="Vertical"
                        onChange={setVerticalValue}
                        value={verticalValue}
                        filterOption={filterOption}
                        options={VERTICAL_OPTIONS}
                      />
                    </Form.Item>
                  </div>
                  <div className={style.btnWrap}>
                    <Button type="primary" htmlType="submit">
                      Confirm
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>
          <div className={style.nav} onClick={() => showFilter('brand')}>
            <div className={style.iconBox}>
              <img className={style.icon} src={LightSvg} />
            </div>
            <div className={style.navTitle}>Vertical Drill Down</div>
            <div className={style.navDesc}>
              Understand how different brands perform across countries,
              subcategories and formats.
            </div>
            <div className={style.navBg} />
            {showFilter2 && (
              <div className={classNames(style.filter, style.brand)}>
                <div
                  className={style.btnClose}
                  onClick={(ev) => closeFilter(ev, 'brand')}
                ></div>
                <h1 className={style.filterTitle}>Filter</h1>
                <Form
                  name="brand"
                  onFinish={onFinishBrand}
                  onFinishFailed={onFinishFailed}
                  className={style.filterContainer}
                >
                  <div className={style.filterFormInner}>
                    <Form.Item
                      label=""
                      name="vertical"
                      rules={[
                        {
                          required: true,
                          message: 'Please select vertical!',
                        },
                      ]}
                      className={classNames(style.greenInput)}
                    >
                      <Select
                        placeholder="Vertical"
                        onChange={setVerticalValue2}
                        value={verticalValue2}
                        filterOption={filterOption}
                        options={VERTICAL_OPTIONS}
                      />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="brand"
                      rules={[
                        {
                          required: true,
                          message: 'Please select brand!',
                        },
                      ]}
                      className={classNames(style.greenInput)}
                    >
                      <Select
                        placeholder="Brand"
                        allowClear
                        showSearch
                        onChange={(newValue) => {
                          handleFilter(newValue, 3);
                        }}
                        value={brandValue}
                        filterOption={filterOption}
                        options={optionsBrand}
                      />
                    </Form.Item>
                  </div>
                  <div className={style.btnWrap}>
                    <Button
                      className={style.btnConfirm}
                      type="primary"
                      htmlType="submit"
                    >
                      Confirm
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
      <VideoBg />
    </div>
  );
}

export default HomePage;
