import React, { useMemo } from 'react';
import style from './box-tab.module.scss';
import Box from './box.jsx';

function PackageTabHeader({ options, active, setActive }) {
  return (
    <div className={style.header}>
      {options.map((option) => (
        <div
          key={option.value}
          className={`${style.option} ${
            active === option.value ? style.active : ''
          }`}
          onClick={() => setActive(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

function PackageAiTab({
  className = '',
  headerRight,
  options,
  active,
  setActive,
}) {
  const ac = useMemo(() => {
    const a = options.find((option) => option.value === active);
    if (a) return a.children;
    return null;
  });

  return (
    <Box
      className={className}
      hasHeader={true}
      headerColorReverse={true}
      title={
        <PackageTabHeader
          options={options}
          active={active}
          setActive={setActive}
        ></PackageTabHeader>
      }
      headerRight={headerRight}
    >
      {ac}
    </Box>
  );
}

export default PackageAiTab;
