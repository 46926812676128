// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qbJohHJ87fMht0ZHn_cH{margin-bottom:10px;border-bottom:solid 2px rgba(255,255,255,.1019607843)}.Dg6ip1WjK3XT3mBwm6cY{margin-bottom:10px;font-size:13px;font-weight:600;line-height:18.12px;cursor:pointer}.mlRW6YCIpgrRl9Jr6O6R{padding:3px;display:none}.xtRshY01SB16Bk7z20hl{display:block}`, "",{"version":3,"sources":["webpack://./src/modules/occasion-module/components/collapse.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,qDAAA,CAGF,sBACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,cAAA,CAGF,sBACE,WAAA,CACA,YAAA,CAGF,sBACE,aAAA","sourcesContent":[".collapseItemContainer {\n  margin-bottom: 10px;\n  border-bottom: solid 2px #ffffff1a;\n}\n\n.collapseItemLabel {\n  margin-bottom: 10px;\n  font-size: 13px;\n  font-weight: 600;\n  line-height: 18.12px;\n  cursor: pointer;\n}\n\n.collapseItemContent {\n  padding: 3px;\n  display: none;\n}\n\n.collapseItemContentActive {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapseItemContainer": `qbJohHJ87fMht0ZHn_cH`,
	"collapseItemLabel": `Dg6ip1WjK3XT3mBwm6cY`,
	"collapseItemContent": `mlRW6YCIpgrRl9Jr6O6R`,
	"collapseItemContentActive": `xtRshY01SB16Bk7z20hl`
};
export default ___CSS_LOADER_EXPORT___;
