// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qb3jISfN8ZQGGTMVK892{position:fixed;left:0;top:0;height:100vh;width:100vw;z-index:2001;overflow:hidden}.yefauK8n3QvoR9a9QDBK{position:absolute;left:0;top:0;height:100%;width:100%;background-color:rgba(0,0,0,.5);z-index:2002}.roU3r9xHOkqZyKp5zWpl{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:2003}.R70RKJGhGdkAn35xLEBQ{height:25px}.LrGZvx9RrvdnKXgMu0Qh{height:100%;color:#00a32e;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/components/dialog.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,MAAA,CACA,KAAA,CACA,YAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA,CACA,+BAAA,CACA,YAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,YAAA,CAGF,sBACE,WAAA,CAGF,sBACE,WAAA,CACA,aAAA,CACA,cAAA","sourcesContent":[".dialogContainer {\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  width: 100vw;\n  z-index: 2001;\n  overflow: hidden;\n}\n\n.dialogModal {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 2002;\n}\n\n.dialogBox {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 2003;\n}\n\n.closeBtn {\n  height: 25px;\n}\n\n.closeIcon {\n  height: 100%;\n  color: #00a32e;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": `qb3jISfN8ZQGGTMVK892`,
	"dialogModal": `yefauK8n3QvoR9a9QDBK`,
	"dialogBox": `roU3r9xHOkqZyKp5zWpl`,
	"closeBtn": `R70RKJGhGdkAn35xLEBQ`,
	"closeIcon": `LrGZvx9RrvdnKXgMu0Qh`
};
export default ___CSS_LOADER_EXPORT___;
