import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import style from './tutorial.module.scss';

const KEY = 'package-ai-inspiration-topline-tutorial';

function Window({ className }) {
  return (
    <div className={classNames(style.window, className)}>
      <div className={classNames(style.corner, style.corner1)} />
      <div className={classNames(style.corner, style.corner2)} />
      <div className={classNames(style.corner, style.corner3)} />
      <div className={classNames(style.corner, style.corner4)} />
      <div className={style.windowInner}></div>
    </div>
  );
}

function Content({ className, title, contents }) {
  return (
    <div className={classNames(style.content, className)}>
      <div className={style.contentTitle}>{title}</div>
      <div className={style.contentText}>
        {contents.map((e, index) => {
          return <p key={index}>{e}</p>;
        })}
      </div>
    </div>
  );
}

function StartLine({ className }) {
  return (
    <div className={classNames(style.startLine, className)}>
      <div className={style.startLineIcon} />
    </div>
  );
}

function VerticalLine({ className }) {
  return <div className={classNames(style.verticalLine, className)} />;
}

function PackageAiTutorial({ className }) {
  const [visible, setVisible] = useState(!localStorage.getItem(KEY));

  const onClick = useCallback(() => {
    setVisible(false);
    localStorage.setItem(KEY, 'true');
  }, [setVisible]);

  if (!visible) return null;

  return (
    <div className={classNames(style.tutorial, className)} onClick={onClick}>
      {/* masks */}
      <div className={classNames(style.mask, style.box1)}></div>
      <div className={style.box2}>
        <div className={classNames(style.mask, style.box21)} />
        <Window className={style.box22} />
        <div className={classNames(style.mask, style.box23)} />
        <Window className={style.box24} />
        <div className={classNames(style.mask, style.box25)} />
        <Window className={style.box26} />
        <div className={classNames(style.mask, style.box27)} />
      </div>
      <div className={classNames(style.mask, style.box3)}></div>
      <div className={style.box4}>
        <div className={classNames(style.mask, style.box41)} />
        <div className={style.box42}>
          <Window className={style.box421} />
          <div className={classNames(style.mask, style.box422)} />
          <Window className={style.box423} />
        </div>
        <div className={classNames(style.mask, style.box43)} />
      </div>
      <div className={classNames(style.mask, style.box5)}></div>
      {/* content */}
      <Content
        className={style.content1}
        title="Topline Metrics"
        contents={[
          'Topline metrics highlight the universal!drivers of pack effectiveness based on the Attract-Engage-Sell model, They are the Brand and Variant visibility (3M VAS), Appeal and Unpriced purchase intent (Consumer Survey).',
          'Answers business questions such as How does our current pack design compare to the category average on Appeal or Purchase intent?',
        ]}
      />
      <Content
        className={style.content2}
        title="Perceived Benefits Deep-dive"
        contents={[
          'Perceived Benefits peep-dive section displays the specific value drivers contributing to consumer perception on benefits based on the evaluation of the pack alone. The benefits measured include Taste,Premium,Healthy,Sustainable Appeal etc.',
          'Answers business questions such as How well does our current pack design communicate Healthiness or Modernity?',
        ]}
      />
      <Content
        className={style.content3}
        title="Compare"
        contents={[
          'The Compare section helps you to compare up to 4 different pack design, within and across categories for any relevant metric.',
          'Answers business questions such as How does my current pack design compare to the top 3 competitor packs across all metrics?',
        ]}
      />
      <Content
        className={style.content4}
        title="Pack Filters"
        contents={[
          'Pack Filters help to drill down and select specific groups of the database for analysis. eg. by category, by country etc.',
        ]}
      />
      <Content
        className={style.content5}
        title="Subgroup Filters"
        contents={[
          'Subgroup analysis help to compare metrics based on different target groups within the same vertical category or brand selection.',
        ]}
      />
      {/* lines */}
      <StartLine className={style.line11} />
      <VerticalLine className={style.line12} />
      <StartLine className={style.line21} />
      <VerticalLine className={style.line22} />
      <StartLine className={style.line31} />
      <VerticalLine className={style.line32} />
      <StartLine className={style.line41} />
      <StartLine className={style.line51} />
    </div>
  );
}

export default PackageAiTutorial;
