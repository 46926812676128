// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WjNiG1dpkBn3xQC84o4T{position:absolute;right:15px;bottom:15px;z-index:2;display:flex;align-items:center}.yxcTKVA__1CYh4ZDKgf3{margin-right:16px;font-size:20px;color:#00a32e}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/edit/tool.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".toolBox {\n  position: absolute;\n  right: 15px;\n  bottom: 15px;\n  z-index: 2;\n  display: flex;\n  align-items: center;\n}\n\n.toolBtn {\n  margin-right: 16px;\n  font-size: 20px;\n  color: #00a32e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolBox": `WjNiG1dpkBn3xQC84o4T`,
	"toolBtn": `yxcTKVA__1CYh4ZDKgf3`
};
export default ___CSS_LOADER_EXPORT___;
