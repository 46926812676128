import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getImageById } from '../../api';

function PackageAiImg({ className, market, sn, alt, onClick }) {
  const [src, setSrc] = useState(null);
  const imgRef = useRef();

  const fetchSrc = useCallback(async () => {
    const f = await getImageById(sn, market);
    setSrc(f);
  }, [setSrc, market, sn]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchSrc();
      }
    });
    observer.observe(imgRef.current);
    return () => observer.disconnect();
  });

  return (
    <img
      ref={imgRef}
      className={className}
      src={src}
      alt={alt}
      loading="lazy"
      onClick={() => {
        if (!src) return;
        onClick(src, alt);
      }}
    />
  );
}

export default PackageAiImg;
