import {
  ZoomInOutlined,
  ZoomOutOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import React from 'react';
import style from './tool.module.scss';

function PackageAiTool({ scale, setScale, onClear }) {
  return (
    <div className={style.toolBox}>
      <ClearOutlined className={style.toolBtn} onClick={onClear} />
      <ZoomInOutlined
        className={style.toolBtn}
        onClick={() => setScale(scale + 0.1)}
      />
      <ZoomOutOutlined
        className={style.toolBtn}
        onClick={() => setScale(scale - 0.1)}
      />
    </div>
  );
}

export default PackageAiTool;
