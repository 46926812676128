import { Input, Select } from 'antd';
import React, { useState } from 'react';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import style from './text.module.scss';

function PackageAiText({ className, addText }) {
  const [size, setSize] = useState(16);
  const [text, setText] = useState();

  function onSubmit() {
    addText && addText(text, size);
    setText(undefined);
  }

  return (
    <div className={`${style.textBox} ${className}`}>
      <Select
        value={size}
        onChange={(value) => setSize(value)}
        options={[
          { label: '8', value: 8 },
          { label: '16', value: 16 },
          { label: '24', value: 24 },
          { label: '48', value: 48 },
          { label: '64', value: 64 },
          { label: '72', value: 72 },
        ]}
      ></Select>
      <Input.TextArea
        style={{ marginTop: '10px' }}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Please enter text"
        autoSize={{ minRows: 3, maxRows: 6 }}
      />
      <div className={style.textBtn}>
        <Btn onClick={onSubmit}>Submit</Btn>
      </div>
    </div>
  );
}

export default PackageAiText;
