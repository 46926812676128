import React from 'react';
import style from './container.module.scss';
import Dropdown from './dropdown.jsx';

function Step({ index, children, step }) {
  return (
    <div className={`${style.step} ${index <= step ? style.stepActive : ''}`}>
      <div className={style.stepIndex}>{index}</div>
      <div className={style.stepText}>{children}</div>
    </div>
  );
}

function PackageAiContainer({ children, step }) {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerTitle}>Package AI</div>
        <div className={style.headerContent}>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step index={1} step={step}>
              Choose your Base Pack
            </Step>
          </Dropdown>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step index={2} step={step}>
              Edit with Easy AI
            </Step>
          </Dropdown>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step index={3} step={step}>
              Update Text and Logo
            </Step>
          </Dropdown>
          <Dropdown
            item={
              <div className={style.stepInfo}>
                This filter shows classification of respondents by demographics
                i.e. Gender, Age, Income Bracket etc.
              </div>
            }
          >
            <Step index={4} step={step}>
              Rendering
            </Step>
          </Dropdown>
        </div>
      </div>
      <div className={style.body}>{children}</div>
    </div>
  );
}

export default PackageAiContainer;
