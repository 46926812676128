import React, { useRef } from 'react';
import './how-to-use.scss';
import { guideData } from '../../api/guide-data.js';
import { triangleRight } from '../../../../components/icons/icons.jsx';

function HowToUse() {
  const targetSectionRef = useRef(null);
  const popupContentRef = useRef(null);

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  const scrollTo = (element, to, duration) => {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to; // Ensure we end at the exact position
      }
    };

    animateScroll();
  };

  const handleScroll = () => {
    if (popupContentRef.current && targetSectionRef.current) {
      const targetOffsetTop = targetSectionRef.current.offsetTop;
      scrollTo(popupContentRef.current, targetOffsetTop, 800);
    }
  };

  return (
    <div className='how-to-use' ref={popupContentRef}>
      <div className='title-container'>
        <div className='title-text'>
          <h1>        
            <img className='right-space' src={triangleRight} alt="" />Deep Dive into Metrics
          </h1>
        </div>
        <div className='scroll-link'>
          <span onClick={handleScroll}>How to use the tool</span>
        </div>
      </div>
      <div className='grid-container'>
        {guideData.data.map((section, index) => (
          <div key={index} className='card'>
            <div className='icon-container pb-10'>
              {section.icon}
            </div>
            <div className='content-container'>
              <p>{section.content}</p>
              {section.subdata && (
                <ul>
                  {section.subdata.map((subitem, subindex) => (
                    <li key={subindex}>
                      <h3>{subitem.subtitle}</h3>
                      <p>{subitem.suncontent}</p>
                    </li>
                  ))}
                </ul>
              )}
              <p>{section.footercontent}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='social-title-text'>
        <h1>
          <img className='right-space' src={triangleRight} alt="" />FAQ's
        </h1>
      </div>
      <div className='social-content'>
        <div className='social-container'>
          {guideData.faq.map((section, index) => (
            <div key={index} className='card'>
              <h3>{section.question}</h3>
              <p>{section.answer}</p>
            </div>
          ))}
        </div>
      </div>

      <div className='social-title-text pt-10' ref={targetSectionRef}>
        <h1>
          <img className='right-space' src={triangleRight} alt="" />How to use the tool
        </h1>
      </div>
      <div className='tool-data'>      
        <img src={guideData.tool.icon} className='pb-20' alt={guideData.tool.title} />
      </div>
    </div>
  );
}

export default HowToUse;
