import React, { useEffect, useMemo, useRef } from 'react';
import { Image as KonvaImage, Layer, Line, Stage } from 'react-konva';
import useImage from 'use-image';
import BrushSvg from '../../assets/img/brush.svg';
import style from './editor.module.scss';

const BRUSH_COLOR = '#00000099';
const BRUSH_SIZES = [0, 5, 15, 45];

function PackageAiLine({ data, posScale }) {
  return (
    <Line
      points={data.points.map((e) => e * posScale)}
      stroke={BRUSH_COLOR}
      strokeWidth={data.size}
      lineCap="round"
      lineJoin="round"
    />
  );
}

function PackageAiEditor({
  url,
  brushScale,
  scale,
  setScale,
  clearCount,
  data,
  setData,
  setDrawRef,
}) {
  const [image] = useImage(url);
  const boxRef = useRef(null);
  const contain = useMemo(() => {
    if (!image) return;
    if (!boxRef.current) return;
    // reset scale
    setScale(1);
    // set contain size
    const s = boxRef.current.clientHeight / image.height;
    return { width: image.width * s, posScale: s };
  }, [image, boxRef]);
  const burshSize = useMemo(() => {
    return BRUSH_SIZES[brushScale];
  }, [brushScale]);

  const isDrawing = useRef(false);

  function handleMouseDown(e) {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setData([
      ...data,
      {
        size: burshSize,
        points: [pos.x / contain.posScale, pos.y / contain.posScale],
      },
    ]);
  }
  function handleMouseMove(e) {
    if (!isDrawing.current) return;
    const pos = e.target.getStage().getPointerPosition();
    const lastPath = data[data.length - 1];
    lastPath.points = lastPath.points.concat([
      pos.x / contain.posScale,
      pos.y / contain.posScale,
    ]);
    setData([...data]);
  }
  function handleMouseUp() {
    if (!isDrawing.current) return;
    isDrawing.current = false;
  }

  function onClear() {
    setData([]);
  }
  useEffect(() => {
    if (clearCount) {
      onClear();
    }
  }, [clearCount]);

  const drawRef = useRef(null);
  useEffect(() => {
    setDrawRef(drawRef.current);
  }, drawRef.current);

  return (
    <div
      className={style.editorBox}
      ref={boxRef}
      style={{
        cursor: `url(${BrushSvg}), pointer`,
        transform: `scale(${scale})`,
      }}
    >
      <Stage
        width={contain?.width}
        height={boxRef.current?.clientHeight}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Layer>
          {image && (
            <KonvaImage
              image={image}
              width={contain?.width}
              height={boxRef.current?.clientHeight}
            />
          )}
        </Layer>
        <Layer ref={drawRef}>
          {data.map((e, i) => (
            <PackageAiLine data={e} key={i} posScale={contain.posScale} />
          ))}
        </Layer>
      </Stage>
    </div>
  );
}

export default PackageAiEditor;
