import React, { useRef, useState, useCallback, useEffect } from 'react';
import style from './style.module.scss';
import { App, Select, Table, Button, Space, Tag, Divider, Upload, Spin, Input } from 'antd';
import { LoadingOutlined, HomeOutlined, InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import { fetchCategory, fetchCountries, fetchInsight, fileUpload, updateSummaryItem, celeryTask } from '../../api/request.js';
import classNames from 'classnames';

const { Dragger } = Upload;
const { TextArea } = Input;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

function debounce (callback, delay) {
  let lastTime;
  return function () {
    clearTimeout(lastTime);
    const [that, args] = [this, arguments];
    lastTime = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

let requestIndex = Date.now();
let scrollTop = 0;

const SummaryPage = () => {
  const domRef = useRef(null);

  const staticFunction = App.useApp();
  const message = staticFunction.message;

  // topic
  const [topicValue, setTopicValue] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  useEffect(() => {
    fetchCategory().then(res => {
      const tabs = [];
      res?.data?.forEach(item => {
        tabs.push({
          label: item.title,
          options: item.topics.map(child => {
            return {
              label: child.title,
              value: child.id
            };
          })
        });
      });
      setTopicOptions(tabs);
    })
      .catch(err => {console.error(err);});
  }, []);

  // country
  const [countryValue, setCountryValue] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    fetchCountries()
      .then(res => {
        const countries = res.data?.map(item => (
          {
            name: item.country,
            value: item.country
          }
        ));
        setCountryOptions(countries);
      })
      .catch(error => console.error(error));
  }, []);

  // table
  const [tableTotalData, setTableTotalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const paginateChange = (pagination) => {
    const {current, pageSize} = pagination;
    const dataList = tableTotalData.filter((item, index) => index >= (current - 1) * pageSize && index < current * pageSize);
    setTableData(dataList);
    setCurrent(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    paginateChange({current: 1, pageSize: pageSize});
  }, [pageSize]);

  useEffect(() => {
    paginateChange({current: 1, pageSize: pageSize});
  }, [tableTotalData]);

  const fetchTableDataFn = (params) => {
    setTableLoading(true);
    fetchInsight(params, requestIndex)
      .then(res => {
        if (requestIndex !== res.config.uuid) return;
        const resData = res.data.data?.map((item, index) => {
          return {
            ...item,
            index: index + 1
          };
        });
        setTableTotalData(Array.isArray(resData) ? resData : []);
        setTotal(Array.isArray(resData) ? resData.length : 0);
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        setTableLoading(false);
      });
  };
  const delayFetchTableData = useCallback(debounce(function(val) { return fetchTableDataFn(val);}, 500), []);

  useEffect(() => {
    const params = {
      topic_id: topicValue,
      country: countryValue,
    };
    requestIndex++;
    delayFetchTableData(params);
  }, [topicValue, countryValue]);

  // 编辑
  const [showUpload, setShowUpload] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const editFn = (item) => {
    scrollTop = domRef.current.parentNode.scrollTop;
    setEditItem(item);
    setImageValue({
      url: item.image_url,
      key: item.image_path,
    });
    setVideoValue({
      url: item.video_url,
      key: item.video_path,
    });
    setTextValue(item.insight);
    setShowUpload(true);
    domRef.current.parentNode.scrollTop = 0;
  };

  const backFn = () => {
    setShowUpload(false);
    setImageValue({
      url: '',
      key: '',
    });
    setVideoValue({
      url: '',
      key: '',
    });
  };

  useEffect(() => {
    if (!showUpload) {
      domRef.current.parentNode.scrollTop = scrollTop;
    }
  }, [showUpload]);

  const columns = [
    {
      title: 'Index',
      dataIndex: 'index',
      width: '100px',
      align: 'center',
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: '200px',
      align: 'center',
    },
    {
      title: 'status',
      dataIndex: 'status',
      width: '200px',
      align: 'center',
      render: (_, { insight, image_url, video_url }) => {
        return <Space size="middle">
          <Tag className={classNames(image_url ? style.tagFull : style.tagDefault)}>status</Tag>
          <Tag className={classNames(video_url ? style.tagFull : style.tagDefault)}>video</Tag>
          <Tag className={classNames(insight ? style.tagFull : style.tagDefault)}>text</Tag>
        </Space>;
      }
    },
    {
      title: 'handle',
      width: '220px',
      align: 'center',
      render: (_, item) => {
        return (<Space size="middle">
          <Button size='small' onClick={() => editFn(item)}>edit</Button>
        </Space>
        );
      },
    }
  ];

  // 获坖坎缀
  function getFileExt(file) {
    if (!file) {
      console.error('The file parameter is empty');
      return '';
    }
    const fileName = file.name || file;
    const fileNameList = fileName.split('.');
    if (fileNameList[fileNameList.length - 1].indexOf('?') === -1) {
      return fileNameList[fileNameList.length - 1];
    }
    return fileNameList[fileNameList.length - 1].split('?')[0];
  }

  // 图片
  const [imageValue, setImageValue] = useState({
    key: '',
    url: ''
  });
  const [imageLoading, setImageLoading] = useState(false);

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <div className={style.uploadIcon}><InboxOutlined /></div>}
      <div className={style.uploadText}>Click and select image</div>
    </div>
  );

  const beforeUploadImage = (file) => {
    const suffix = getFileExt(file);
    const validate = ['jpeg', 'jpg', 'jpe', 'png'].includes(
      suffix
    );
    if (!validate) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      message.error('Image must smaller than 100MB!');
      return;
    }
    setImageLoading(true);
    const formData = new FormData();
    formData.append('prefix', 'consumer-theater');
    formData.append('form', 'form');
    formData.append('file', file);
    fileUpload(formData)
      .then((res) => {
        setImageValue({
          url: res.data.url, 
          key: res.data.key,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setImageLoading(false);
      });
    return false;
  };

  // 视频
  const [videoValue, setVideoValue] = useState({
    key: '',
    url: ''
  });
  const [videoLoading, setVideoLoading] = useState(false);

  const uploadButtonVideo = (
    <div>
      {videoLoading ? <LoadingOutlined /> : <div className={style.uploadIcon}><InboxOutlined /></div>}
      <div className={style.uploadText}>Click and select video</div>
    </div>
  );

  const beforeUploadVideo = (file) => {
    const suffix = getFileExt(file);
    const validate = ['mp4', 'webm', 'ogg'].includes(
      suffix
    );
    if (!validate) {
      message.error('You can only upload MP4/WebM/OGG file!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 1000;
    if (!isLt2M) {
      message.error('Image must smaller than 1000MB!');
      return;
    }
    setVideoLoading(true);
    const formData = new FormData();
    formData.append('prefix', 'consumer-theater');
    formData.append('form', 'form');
    formData.append('file', file);
    fileUpload(formData)
      .then((res) => {
        setVideoValue({
          url: res.data.url, 
          key: res.data.key,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setVideoLoading(false);
      });
    return false;
  };

  // 文本
  const [textValue, setTextValue] = useState('');
  const onChange = (e) => {
    setTextValue(e.target.value);
  };

  // 杝交
  const [submitLoading, setSubmitLoading] = useState(false);
  const submit = () => {
    if (imageLoading) {
      message.warning('Wait for the image to finish uploading!');
      return;
    }
    if (videoLoading) {
      message.warning('Wait for the video upload to finish!');
      return;
    }
    const data = {
      ...editItem,
      image_path: imageValue.key,
      video_path: videoValue.key,
      insight: textValue
    };
    setSubmitLoading(true);
    updateSummaryItem(data)
      .then(() => {
        const index = tableTotalData.findIndex(item => item.id === editItem.id);
        tableTotalData[index] = {
          ...tableTotalData[index],
          image_url: imageValue.url,
          video_url: videoValue.url,
          insight: textValue
        };
        const dataList = tableTotalData.filter((item, index) => index >= (current - 1) * pageSize && index < current * pageSize);
        setTableData(dataList);
        backFn();
        message.success('submit successfully');
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const rowClassName = (record) => {
    return editItem && editItem.id === record.id ? 'editing' : '';
  };

  // 统计标签
  const [startLoading, setStartLoading] = useState(false);
  const startFn = () => {
    const lastTime = window.localStorage.getItem('consumer_theater_summary_start_time');
    if (lastTime && lastTime > Date.now() - 600000) {
      message.warning(`Statistical label tasks can only be started once in 10 minutes! Last start: ${new Date(+lastTime)}`);
      return;
    }
    setStartLoading(true);
    celeryTask({
      task_name: 'update_per_topic_tags_summary'
    })
      .then(() => {
        message.success('The statistics label task is started successfully');
        window.localStorage.setItem('consumer_theater_summary_start_time', Date.now());
        setTimeout(() => {
          setStartLoading(false);
        }, 3000);
      })
      .catch(error => {
        message.error('Description Failed to start the statistics label task');
        console.error(error);
        setStartLoading(false);
      });
  };

  return <div className={style.pageContainer} ref={domRef}>
    {!showUpload && <>
      <div className={style.pageHeader}>Summary</div>
      <div className={style.selectBox}>
        <div className={style.selectItem}>
          <div className={style.selectLabel}>Topic </div>
          <Select
            style={{ width: '100%' }}
            placeholder="select one topic"
            allowClear
            value={topicValue}
            onChange={(newValue) => {
              setTopicValue(newValue);
            }}
            optionLabelProp="label"
            showSearch
            options={topicOptions}
            popupMatchSelectWidth={false}
          />
        </div>
        <div className={style.selectItem}>
          <div className={style.selectLabel}>Country </div>
          <Select
            style={{ width: '100%' }}
            placeholder="select one country"
            allowClear
            value={countryValue}
            onChange={(newValue) => {
              setCountryValue(newValue);
            }}
            optionLabelProp="label"
            showSearch
            options={countryOptions}
            popupMatchSelectWidth={false}
          />
        </div>
        <Button type="primary" shape="round" icon={<ReloadOutlined />} loading={startLoading} onClick={startFn}>check</Button>
      </div>
      <div className={style.tableBox}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={tableData}
          loading={{spinning: tableLoading, indicator: antIcon}}
          onChange={paginateChange}
          rowClassName={rowClassName}
          pagination={{
            position: ['bottomCenter'],
            showTotal: total => `Total ${total}`,
            showQuickJumper: true,
            current,
            pageSize,
            total,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30, 40],
          }}
        />
      </div>
    </>
    }
    {showUpload && <>
      <div className={style.pageSubHeader}>
        <Button className={style.backBtn} type="text" icon={<HomeOutlined />} onClick={() => backFn()}>Back</Button>
        <div className={style.pageHeader}>Upload</div>
        <Button type="primary" shape="round" onClick={submit} loading={submitLoading}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </Button>
      </div>
      <Divider plain></Divider>
      <div className={style.uploadContainer}>
        <div className={style.uploadItem}>
          <div className={style.uploadItemTitle}>Cover Page</div>
          <div className={classNames(style.uploadItemContent, style.itemImage)}>
            <Dragger
              name="avatar"
              accept={['image/*']}
              className="avatar-uploader"
              showUploadList={false}
              action=""
              beforeUpload={beforeUploadImage}
              disabled={imageLoading}
            >
              {imageValue.url ? (
                <Spin
                  wrapperClassName={style.updateImageLoading}
                  indicator={antIcon}
                  spinning={imageLoading}
                >
                  <div className={style.imgBox} style={{backgroundImage: `url(${imageValue.url})`}}></div>
                </Spin>
              ) : (
                uploadButton
              )}
            </Dragger>
          </div>
        </div>
        <div className={style.uploadItem}>
          <div className={style.uploadItemTitle}>Summary Video</div>
          <div className={classNames(style.uploadItemContent, style.itemImage)}>
            <Dragger
              name="avatar"
              accept={['video/*']}
              className="avatar-uploader"
              showUploadList={false}
              action=""
              beforeUpload={beforeUploadVideo}
              disabled={videoLoading}
            >
              {videoValue.url ? (
                <Spin
                  wrapperClassName={style.updateImageLoading}
                  indicator={antIcon}
                  spinning={videoLoading}
                >
                  <video className={style.videoBox} src={videoValue.url} controls></video>
                </Spin>
              ) : (
                uploadButtonVideo
              )}
            </Dragger>
          </div>
        </div>
      </div>
      <div className={style.uploadItem}>
        <div className={style.uploadItemTitle}>Text</div>
        <div className={style.uploadItemContent}>
          <TextArea
            value={textValue}
            style={{
              height: '100%',
              resize: 'none',
            }}
            onChange={onChange}
            placeholder="please enter..."
          />
        </div>
      </div>
    </>
    }
  </div>;
};

export default SummaryPage;