import { App, ConfigProvider, message, theme } from 'antd';
import React, { useRef } from 'react';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 as Btn } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import style from './index.module.scss';

function PackageAiView() {
  const context = JSON.parse(
    sessionStorage.getItem('packageAiContext') ?? '{}'
  );
  const url = context.update.imageUrl;

  const aRef = useRef(null);
  function onDownload() {
    if (!aRef.current) return;
    aRef.current.click();
  }

  if (!url) {
    message.error('Please select a package first');
    window.location.href = '/package-ai-generation';
    return;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={4}>
          <Box className={style.box}>
            <div className={style.box1}>
              <div className={style.imgBox}>
                <img src={url} />
              </div>
              <Btn className={style.btn1} onClick={onDownload}>
                Download
              </Btn>
            </div>
          </Box>
        </Container>
      </App>
      <a href={url} download style={{ display: 'none' }}></a>
    </ConfigProvider>
  );
}

export default PackageAiView;
