import { Input, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getFilter } from '../../api';
import style from './index.module.scss';
import { VERTICAL_OPTIONS } from './vertical';

const GENERATION_OPTIONS = [
  { label: 'Gen X', value: 'Gen X' },
  { label: 'Millennials', value: 'Millennials' },
  { label: 'Gen Z', value: 'Gen Z' },
  { label: 'Baby Boomers', value: 'Baby Boomers' },
];
const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
const USER_VS_NONE_USER_OPTIONS = [
  { label: 'Users', value: 'Users' },
  { label: 'Non-Users', value: 'Non-Users' },
];
const SOCIAL_CLASS_OPTIONS = [
  { label: 'Low Income', value: 'Low Income' },
  { label: 'Middle Income', value: 'Middle Income' },
  { label: 'High Income', value: 'High Income' },
];

function filterOption(input, option) {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

function PackageAiFilters({
  className = '',
  showSearch = true,
  search,
  setSearch = () => {},
  showFilter = true,
  data = {},
  setData = () => {},
  showSubFilters = true,
  subData = {},
  setSubData = () => {},
}) {
  const [options, setOptions] = useState({
    market: [],
    category: [],
    company: [
      { label: 'Unilever', value: 'Unilever' },
      { label: 'Competition', value: 'Competition' },
    ],
    brand: [],
  });
  const [companyOptions, setCompanyOptions] = useState([]);
  const companyData = useMemo(() => {
    if (!data.company?.length) return [];
    else if (data.company.length === 1) return ['Unilever'];
    else if (data.company.includes('Unilever')) {
      return ['Unilever', 'Competition'];
    } else return ['Competition'];
  }, [data]);

  // market options
  const getOptions = useCallback(async () => {
    const { data: md } = await getFilter('market');
    const { data: cd } = await getFilter('category');
    const { data: cd1 } = await getFilter('company');
    const { data: bd } = await getFilter('brand');

    const buildOptions = (data) => {
      if (!data) return [];
      return data.map((item) => ({ label: item, value: item }));
    };

    setOptions((prev) => {
      return {
        ...prev,
        market: buildOptions(md),
        category: buildOptions(cd),
        brand: buildOptions(bd),
      };
    });

    setCompanyOptions(cd1);
  }, [setOptions, setCompanyOptions]);
  useEffect(() => {
    void getOptions().catch((err) => {
      console.error('get options error', err);
    });
  }, []);

  const onMarketChange = useCallback(
    (val) => {
      setData({ ...data, market: val });
    },
    [data, setData]
  );

  const onVerticalChange = useCallback(
    (val) => {
      setData({ ...data, vertical: val });
    },
    [data, setData]
  );

  const onCategoryChange = useCallback(
    (val) => {
      setData({ ...data, category: val });
    },
    [data, setData]
  );

  const onCompanyChange = useCallback(
    (val) => {
      const f = { ...data, company: [] };
      if (val.length === 1) {
        if (val[0] === 'Unilever') {
          f.company = val;
        } else if (val[0] === 'Competition') {
          f.company = companyOptions
            .filter((item) => item !== 'Unilever')
            .map((item) => item);
        } else {
          console.warn('unkown company value', val[0]);
        }
      } else if (val.length === 2) {
        f.company = companyOptions;
      }
      setData(f);
    },
    [companyOptions, data, setData]
  );

  const onBrandChange = useCallback(
    (val) => {
      setData({ ...data, brand: val });
    },
    [setData, data]
  );

  const onClearData = useCallback(() => {
    setData({});
  }, [setData]);

  const onClearSubData = useCallback(() => {
    setSubData({});
  }, [setSubData]);

  const subFilterDisplay = useMemo(() => {
    const sfd = {
      generation: false,
      gender: false,
      user_vs_nouser: false,
      social_class: false,
    };
    const oks = Object.keys(subData);
    if (oks.length === 0) {
      sfd.generation = true;
      sfd.gender = true;
      sfd.user_vs_nouser = true;
      sfd.social_class = true;
    } else {
      sfd[oks[0]] = true;
    }
    return sfd;
  }, [subData]);

  return (
    <div className={`${style.searchWrap} ${className}`}>
      {/* Search */}
      {showSearch && (
        <div className={style.searchInputBox}>
          <Input
            className={style.searchInput}
            placeholder="Search"
            onChange={setSearch}
            value={search}
          />
        </div>
      )}
      {/* Pack Filter */}
      {showFilter && (
        <>
          <div className={style.title}>
            <s className={style.icon}></s>
            <span className={style.text}>Pack Filter</span>
            <div className={style.clearBtn} onClick={onClearData}>
              Clear
            </div>
          </div>
          <h5 className={style.searchLabel}>Market</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Market"
              mode="multiple"
              filterOption={filterOption}
              value={data.market}
              onChange={onMarketChange}
              options={options.market}
            />
          </div>
          <h5 className={style.searchLabel}>Vertical</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Vertical"
              mode="multiple"
              filterOption={filterOption}
              value={data.vertical}
              onChange={onVerticalChange}
              options={VERTICAL_OPTIONS}
            />
          </div>
          <h5 className={style.searchLabel}>Category</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Category"
              mode="multiple"
              filterOption={filterOption}
              value={data.category}
              onChange={onCategoryChange}
              options={options.category}
            />
          </div>
          <h5 className={style.searchLabel}>Company</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Company"
              mode="multiple"
              filterOption={filterOption}
              value={companyData}
              onChange={onCompanyChange}
              options={options.company}
            />
          </div>
          <h5 className={style.searchLabel}>Brand</h5>
          <div className={style.myInput}>
            <Select
              placeholder="Brand"
              mode="multiple"
              filterOption={filterOption}
              value={data.brand}
              onChange={onBrandChange}
              options={options.brand}
            />
          </div>
        </>
      )}
      {/* Subgroup Filters */}
      {showSubFilters && (
        <>
          <div className={style.title}>
            <s className={style.icon}></s>
            <span className={style.text}>Subgroup Filters</span>
            <div className={style.clearBtn} onClick={onClearSubData}>
              Clear
            </div>
          </div>
          {subFilterDisplay.generation && (
            <>
              <h5 className={style.searchLabel}>Generation</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Generation"
                  mode="multiple"
                  filterOption={filterOption}
                  value={subData.generation}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ generation: val });
                    }
                  }}
                  options={GENERATION_OPTIONS}
                />
              </div>
            </>
          )}
          {subFilterDisplay.gender && (
            <>
              <h5 className={style.searchLabel}>Gender</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Gender"
                  mode="multiple"
                  filterOption={filterOption}
                  value={subData.gender}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ gender: val });
                    }
                  }}
                  options={GENDER_OPTIONS}
                />
              </div>
            </>
          )}
          {subFilterDisplay.user_vs_nouser && (
            <>
              <h5 className={style.searchLabel}>Category Users vs Non-Users</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Category Users vs Non-Users"
                  mode="multiple"
                  value={subData.user_vs_nouser}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ user_vs_nouser: val });
                    }
                  }}
                  options={USER_VS_NONE_USER_OPTIONS}
                />
              </div>
            </>
          )}
          {subFilterDisplay.social_class && (
            <>
              <h5 className={style.searchLabel}>Social Class</h5>
              <div className={style.myInput}>
                <Select
                  placeholder="Social Class"
                  mode="multiple"
                  filterOption={filterOption}
                  value={subData.social_class}
                  onChange={(val) => {
                    if (!val.length) {
                      setSubData({});
                    } else {
                      setSubData({ social_class: val });
                    }
                  }}
                  options={SOCIAL_CLASS_OPTIONS}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PackageAiFilters;
