/* eslint-disable indent */
import { ArrowRightOutlined } from '@ant-design/icons';
import { Checkbox, Col, message, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  postPredictVariant,
  putPredictPackageTemplate
} from '../../apis/serve';
import HelpPng from '../../assets/img/help2.png';
import Box from '../../components/box.jsx';
import {
  PackageAiBtn1 as Btn3,
  PackageAiBtn2 as Btn2
} from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import Dialog from '../../components/dialog.jsx';
import EditableText from '../../components/editableText.jsx';
import Loading from '../../components/loading.jsx';
import Stage from '../../components/stage.jsx';
import TemplateDialog from '../../components/template.jsx';
import Title from '../../components/title2.jsx';
import { checkAoi } from './checkAoi.js';
import Design from './design.jsx';
import style from './index.module.scss';

function buildAoiPoints(aoi, scale = 1) {
  if (!aoi.points) return undefined;
  const [lt, rb] = aoi.points;
  lt.x = Math.round(lt.x * scale);
  lt.y = Math.round(lt.y * scale);
  rb.x = Math.round(rb.x * scale);
  rb.y = Math.round(rb.y * scale);
  const rt = { x: rb.x, y: lt.y };
  const lb = { x: lt.x, y: rb.y };
  return [lt, rt, rb, lb];
}

function buildAoi(name) {
  return {
    name,
    type: 'rect',
  };
}
function buildAois() {
  return [
    buildAoi('Brand Logo'),
    buildAoi('Variant Name'),
    buildAoi('Secondary Element that helps with Brand Blocking (Optional)'),
    buildAoi(
      'Secondary Element that helps with Variant Differentiation (Optional)'
    ),
  ];
}

function Info() {
  return (
    <div className={style.info}>
      <p>
        In this section, click each pack image to highlight the key design
        elements and DBAs on the pack. Please try to be as accurate as possible
        with the highlighting.
      </p>
    </div>
  );
}

function useDesigns(
  context,
  designs,
  setDeisngs,
  unileverBrandName,
  setHelpVisible
) {
  const bo = context.basic.businessObjective;

  const setDesign = useCallback(
    (index, design) => {
      const newDesigns = [...designs];
      newDesigns[index] = design;
      setDeisngs(newDesigns);
    },
    [designs, setDeisngs]
  );

  return context.asset.unilever.map((d, i) => {
    let dn = unileverBrandName;
    if (i === 0) {
      dn += ' Current';
    } else if (i === 1) {
      dn += ' New Design';
    } else {
      dn += ' New Design ' + i;
    }

    if (bo === 'Competitor Benchmarking' && i === 0) {
      return null;
    } else if (bo === 'Innovation' && i > 0) {
      return null;
    } else {
      return (
        <Col key={d.id} span={6}>
          <Design
            className={style.design}
            numberOfVariantsPerDesign={context.basic.numberOfVariantsPerDesign}
            designName={dn}
            assetDesign={d}
            design={designs[i]}
            setDesign={(design) => setDesign(i, design)}
            onHelpClick={() => setHelpVisible(true)}
          />
        </Col>
      );
    }
  });
}

function PackageAiView() {
  const navigate = useNavigate();
  const [context, setContext] = useState({
    basic: {
      businessObjective: 'Redesign',
      numberOfOwnNewPackDesigns: 1,
      numberOfVariantsPerDesign: 1,
    },
    asset: {
      unilever: [],
      competitor1: { variants: [{}] },
      competitor2: { variants: [{}] },
    },
    aoi: {
      unilever: [],
      competitor1: { variants: [{ aois: buildAois() }] },
      competitor2: { variants: [{ aois: buildAois() }] },
    },
  });

  const isRedesign = useMemo(() => {
    return context.basic.businessObjective === 'Redesign';
  }, [context]);

  const unileverBrandName = useMemo(() => {
    return context.basic?.unileverBrandName ?? 'Unilever Brand Name';
  }, [context]);
  const setUnileverBrandName = useCallback(
    (value) => {
      setContext((ctx) => ({
        ...ctx,
        basic: {
          ...ctx.basic,
          unileverBrandName: value,
        },
      }));
    },
    [setContext]
  );

  const competitor1BrandName = useMemo(() => {
    return context.basic?.competitor1BrandName ?? 'Competitor 1 Brand Name';
  }, [context]);
  const setCompetitor1BrandName = useCallback(
    (value) => {
      setContext((ctx) => ({
        ...ctx,
        basic: {
          ...ctx.basic,
          competitor1BrandName: value,
        },
      }));
    },
    [setContext]
  );

  const competitor2BrandName = useMemo(() => {
    return context.basic?.competitor2BrandName ?? 'Competitor 2 Brand Name';
  }, [context]);
  const setCompetitor2BrandName = useCallback(
    (value) => {
      setContext((ctx) => ({
        ...ctx,
        basic: {
          ...ctx.basic,
          competitor2BrandName: value,
        },
      }));
    },
    [setContext]
  );

  const allowPublic = useMemo(() => {
    return context.asset?.allowPublic ?? true;
  }, [context]);
  const setAllowPublic = useCallback(
    (ev) => {
      setContext((ctx) => ({
        ...ctx,
        asset: {
          ...ctx.asset,
          allowPublic: ev.target.checked,
        },
      }));
    },
    [setContext]
  );

  function init() {
    const ctxs = sessionStorage.getItem('packageAiContext');
    if (!ctxs) {
      message.error('Please fill in the basic information first');
      navigate('/package-ai-screening-basic');
      return null;
    }
    const ctx = JSON.parse(ctxs);
    if (!ctx.aoi) {
      ctx.aoi = {};
      ctx.aoi.unilever = ctx.asset.unilever.map((d) => {
        return {
          id: d.id,
          variants: d.variants.map((v) => {
            return {
              id: v.id,
              aois: buildAois(),
            };
          }),
        };
      });
      ctx.aoi.competitor1 = {
        id: ctx.asset.competitor1.id,
        variants: ctx.asset.competitor1.variants.map((v) => {
          return {
            id: v.id,
            aois: buildAois(),
          };
        }),
      };
      ctx.aoi.competitor2 = {
        id: ctx.asset.competitor2.id,
        variants: ctx.asset.competitor2.variants.map((v) => {
          return {
            id: v.id,
            aois: buildAois(),
          };
        }),
      };
    }
    setContext(ctx);
  }
  useEffect(() => {
    init();
  }, []);

  const onSetDesignU = useCallback(
    (designs) => {
      setContext((c) => {
        const nc = { ...c };
        nc.aoi.unilever = designs;
        return nc;
      });
    },
    [setContext]
  );
  const onSetDesignC1 = useCallback(
    (design) => {
      setContext((c) => {
        const nc = { ...c };
        nc.aoi.competitor1 = design;
        return nc;
      });
    },
    [setContext]
  );
  const onSetDesignC2 = useCallback(
    (design) => {
      setContext((c) => {
        const nc = { ...c };
        nc.aoi.competitor2 = design;
        return nc;
      });
    },
    [setContext]
  );

  const [loading, setLoading] = useState(false);

  async function postAoi(id, aois) {
    // use logo aoi
    const logoAoi = buildAoiPoints(aois[0]);
    // use brand
    const brandAoi = buildAoiPoints(aois[1]);
    // use variant 1
    const variantAoi1 = buildAoiPoints(aois[2]);
    // use variant 2
    const variantAoi2 = buildAoiPoints(aois[3]);
    await postPredictVariant({
      id,
      points: {
        logo: logoAoi,
        brand: brandAoi,
        variant: variantAoi1,
        aoi: variantAoi2,
      },
    });
  }

  const complete = useMemo(() => {
    const bo = context.basic.businessObjective;
    const aoi = context.aoi;
    const cl = [];
    // check unilever
    for (let i = 0; i < aoi.unilever.length; i++) {
      if (bo === 'Competitor Benchmarking' && i === 0) continue;
      else if (bo === 'Innovation' && i > 0) continue;
      const d = aoi.unilever[i];
      for (const v of d.variants) {
        cl.push(checkAoi(v.aois[0]));
        cl.push(checkAoi(v.aois[1]));
      }
    }
    // check competitor1
    for (const v of aoi.competitor1.variants) {
      cl.push(checkAoi(v.aois[0]));
      cl.push(checkAoi(v.aois[1]));
    }
    // check competitor2
    for (const v of aoi.competitor2.variants) {
      cl.push(checkAoi(v.aois[0]));
      cl.push(checkAoi(v.aois[1]));
    }
    return cl.every((v) => v);
  }, [context]);

  async function onContinue() {
    if (loading) return;
    setLoading(true);
    try {
      // use predictPackage
      const ppRes = context.res.predictPackage;
      // post aoi
      for (let i = 0; i < context.aoi.unilever.length; i++) {
        // use design
        const d = context.aoi.unilever[i];
        for (let j = 0; j < d.variants.length; j++) {
          // use variant
          const v = d.variants[j];
          const key = `ul_design${i}_variant${j}_id`;
          const id = ppRes[key];
          if (!id) continue;
          await postAoi(id, v.aois);
        }
      }
      for (let i = 0; i < context.aoi.competitor1.variants.length; i++) {
        const v = context.aoi.competitor1.variants[i];
        const key = `c1_design1_variant${i}_id`;
        const id = ppRes[key];
        if (!id) continue;
        await postAoi(id, v.aois);
      }
      for (let i = 0; i < context.aoi.competitor2.variants.length; i++) {
        const v = context.aoi.competitor2.variants[i];
        const key = `c2_design1_variant${i}_id`;
        const id = ppRes[key];
        if (!id) continue;
        await postAoi(id, v.aois);
      }
      // update context
      const nc = {
        ...context,
        res: { ...context.res },
      };
      sessionStorage.setItem('packageAiContext', JSON.stringify(nc));
      navigate('/package-ai-screening-wait');
    } catch (err) {
      console.error('Error in onContinue', err);
      message.error('Error in onContinue');
    }
    setLoading(false);
  }

  const [helpVisible, setHelpVisible] = useState(false);

  const [templateVisible, setTemplateVisible] = useState(false);

  const onSaveTemplate = useCallback(() => {
    setTemplateVisible(true);
  }, [setTemplateVisible]);
  const onTemplateSubmit = useCallback(
    async (values) => {
      if (!complete) return;
      if (!isRedesign) return;

      setLoading(true);
      try {
        const { templateName, isPublic } = values;
        const cc = JSON.parse(JSON.stringify(context));
        let id = cc.res?.predictPackage?.id;
        if (id) {
          delete cc.res;
        } else {
          console.warn('null pp');
          return;
        }

        const processVariant = (vs) => {
          for (const v of vs) {
            delete v.url;
            delete v.standardUrl;
            delete v.editedUrl;
          }
        };

        for (const d of cc.asset.unilever) {
          processVariant(d.variants);
        }
        processVariant(cc.asset.competitor1.variants);
        processVariant(cc.asset.competitor2.variants);

        delete cc.asset.unilever[1].variants[0].file;
        delete cc.asset.unilever[1].variants[0].standardFile;
        delete cc.asset.unilever[1].variants[0].complete;
        delete cc.asset.unilever[1].variants[0].editedFile;
        delete cc.asset.unilever[1].variants[0].x;
        delete cc.asset.unilever[1].variants[0].y;

        await putPredictPackageTemplate(id, templateName, isPublic, cc);
      } catch (err) {
        console.error('post template error', err);
      }
      setLoading(false);
    },
    [setLoading, context]
  );

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={3} />
        <Box title="Highlight Key Design Elements" className={style.box}>
          <div className={style.form}>
            <Info />
            {!(
              context.basic.businessObjective === 'Competitor Benchmarking' &&
              context.basic.numberOfOwnNewPackDesigns === 0
            ) && (
              <>
                <Title>
                  <EditableText
                    value={unileverBrandName}
                    onChange={setUnileverBrandName}
                  />
                </Title>
                <Row gutter={12}>
                  {useDesigns(
                    context,
                    context.aoi.unilever,
                    onSetDesignU,
                    unileverBrandName,
                    setHelpVisible
                  )}
                </Row>
              </>
            )}
            <Row gutter={12}>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Title>
                      <EditableText
                        value={competitor1BrandName}
                        onChange={setCompetitor1BrandName}
                      />
                    </Title>
                    <Design
                      className={style.design}
                      numberOfVariantsPerDesign={
                        context.basic.numberOfVariantsPerDesign
                      }
                      designName={competitor1BrandName}
                      assetDesign={context.asset.competitor1}
                      design={context.aoi.competitor1}
                      setDesign={onSetDesignC1}
                      onHelpClick={() => setHelpVisible(true)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Title>
                      <EditableText
                        value={competitor2BrandName}
                        onChange={setCompetitor2BrandName}
                      />
                    </Title>
                    <Design
                      className={style.design}
                      numberOfVariantsPerDesign={
                        context.basic.numberOfVariantsPerDesign
                      }
                      designName={competitor2BrandName}
                      assetDesign={context.asset.competitor2}
                      design={context.aoi.competitor2}
                      setDesign={onSetDesignC2}
                      onHelpClick={() => setHelpVisible(true)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={style.btnsBox}>
            {isRedesign && (
              <Btn3
                className={style.btn2}
                onClick={onSaveTemplate}
                suffix={<ArrowRightOutlined />}
                disabled={!complete}
              >
                SAVE AS TEMPLATE
              </Btn3>
            )}
            <Btn2
              className={style.btn2}
              onClick={onContinue}
              suffix={<ArrowRightOutlined />}
              disabled={!complete}
            >
              CONTINUE
            </Btn2>
            <Checkbox checked={allowPublic} onChange={setAllowPublic}>
              Publish my testing results in the Sous-Chef library (visible for
              others). Deselect it for keeping results only visible to you
            </Checkbox>
          </div>
        </Box>
      </div>
      <Dialog visible={helpVisible} setVisible={setHelpVisible} hasBox={false}>
        <img src={HelpPng} alt="help" className={style.helpImg} />
      </Dialog>
      <TemplateDialog
        visible={templateVisible}
        setVisible={setTemplateVisible}
        onSubmit={onTemplateSubmit}
      />
      {loading && <Loading />}
    </Container>
  );
}

export default PackageAiView;
