import { UploadOutlined } from '@ant-design/icons';
import { Form, Select, message } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { postFile } from '../../apis/file';
import { postPackageAiAsset } from '../../apis/serve/index.js';
import BrandJson from '../../assets/json/brand.json';
import CategoryJson from '../../assets/json/category.json';
import { PackageAiBtn1 as Btn } from '../../components/btn.jsx';
import Dialog from '../../components/dialog.jsx';
import style from './upload.module.scss';

const { Item } = Form;

function PackageAiUploadForm({ onSuccess }) {
  /**
   * @type {React.MutableRefObject<HTMLInputElement>}
   */
  const inputRef = useRef(null);

  function onUpload() {
    if (!inputRef.current) return;
    inputRef.current.click();
  }

  const [file, setFile] = useState(null);
  const fileName = useMemo(() => {
    if (!file) return null;
    return file.name;
  });
  async function onFileChange(ev) {
    if (!ev.target?.files?.length) return;
    setFile(ev.target.files[0]);
  }

  const buttonRef = useRef(null);
  function onSubmit() {
    if (!buttonRef.current) return;
    buttonRef.current.click();
  }

  const [loading, setLoading] = useState(false);
  async function onFinish(values) {
    if (loading) return;
    if (!file) {
      message.warning('Please select a image');
      return;
    }
    try {
      setLoading(true);
      const { brand, category } = values;
      const {
        data: { key },
      } = await postFile(file);
      await postPackageAiAsset({
        url: key,
        title: fileName,
        brand,
        category,
        type: 'logo',
      });
      message.success('Upload success');
      onSuccess && onSuccess();
    } catch (err) {
      console.error('upload error', err);
      message.error('Upload failed');
    }
    setLoading(false);
  }

  return (
    <Form className={style.formBox} onFinish={onFinish}>
      <Item label="Upload image">
        <Btn className={style.formUploadBtn} onClick={onUpload}>
          {fileName ?? 'Upload'}
        </Btn>
      </Item>
      <Item label="Brand" name="brand">
        <Select
          options={BrandJson.map((e) => {
            return {
              label: e,
              value: e,
            };
          })}
        ></Select>
      </Item>
      <Item label="Category" name="category">
        <Select
          options={CategoryJson.map((e) => {
            return {
              label: e,
              value: e,
            };
          })}
        ></Select>
      </Item>
      <div className={style.formBtnLine}>
        <Btn onClick={onSubmit}>Submit</Btn>
        <button
          ref={buttonRef}
          type="submit"
          style={{ display: 'none' }}
        ></button>
      </div>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={onFileChange}
      />
    </Form>
  );
}

function PackageAiUpload() {
  const [visible, setVisible] = React.useState(false);

  return (
    <div className={style.uploadBox}>
      <UploadOutlined
        className={style.uploadIcon}
        onClick={() => setVisible(true)}
      />
      <Dialog title="Upload" visible={visible} setVisible={setVisible}>
        <PackageAiUploadForm
          onSuccess={() => setVisible(false)}
        ></PackageAiUploadForm>
      </Dialog>
    </div>
  );
}

export default PackageAiUpload;
