import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { putFile } from '../../api';
import style from './item.module.scss';

function Score({ className, title = 'Title', value = 0 }) {
  const colorClassName = useMemo(() => {
    if (value > 70) return style.dataGreen;
    else if (value > 40) return style.dataYellow;
    else return style.dataRed;
  }, [value]);

  return (
    <div className={classNames(style.scoreContainer, className)}>
      <div className={style.scoreHeader}>
        <span className={style.scoreHeaderText}>{title}</span>
      </div>
      <div className={style.scoreBody}>
        <div className={classNames(style.scoreCircle, colorClassName)} />
        <div className={classNames(style.scoreValue, colorClassName)}>
          {value.toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export function PackageAiItem({
  className,
  title = 'Title',
  brand = 0,
  appeal = 0,
  upi = 0,
  hasParent = false,
  hasChildren = false,
  showChildren = false,
  setShowChildren = () => {},
  leftChildren = false,
}) {
  return (
    <div
      className={classNames(
        style.itemContainer,
        {
          [style.childLeft]: leftChildren,
        },
        className
      )}
    >
      <div className={style.itemHeader}>
        <div className={style.itemHeaderText}>{title}</div>
      </div>
      <div className={style.itemBody}>
        <Score
          title={
            <>
              Brand
              <br />
              Visibility
            </>
          }
          value={brand}
        />
        <Score title="Appeal" value={appeal} />
        <Score
          title={
            <>
              Unpriced
              <br />
              Purchase Inent
            </>
          }
          value={upi}
        />
      </div>
      {hasParent && <div className={style.parentLine} />}
      {hasChildren && showChildren ? (
        <>
          <div className={style.childLine} />
          <div
            className={style.childIcon2}
            onClick={() => setShowChildren(false)}
          >
            <MinusOutlined />
          </div>
        </>
      ) : (
        <div className={style.childIcon} onClick={() => setShowChildren(true)}>
          <PlusOutlined />
        </div>
      )}
    </div>
  );
}

export function PackageAiProduct({
  className,
  title = 'Title',
  image,
  brand = 0,
  appeal = 0,
  upi = 0,
  indexPosition = 0,
}) {
  const [src, setSrc] = useState();
  useEffect(() => {
    putFile(image)
      .then((u) => {
        setSrc(u);
      })
      .catch((err) => {
        console.error('get image error', err);
      });
  }, [image]);

  return (
    <div
      className={classNames(
        style.itemContainer,
        style.productContainer,
        className
      )}
    >
      <div className={style.itemHeader}>
        <div className={style.itemHeaderText}>{title}</div>
      </div>
      <div className={style.itemBody}>
        <div className={style.productImgBox}>
          <img loading="lazy" src={src} alt={title} />
        </div>
        <Score
          title={
            <>
              Brand
              <br />
              Visibility
            </>
          }
          value={brand}
        />
        <Score title="Appeal" value={appeal} />
        <Score
          title={
            <>
              Unpriced
              <br />
              Purchase Inent
            </>
          }
          value={upi}
        />
      </div>
      <div className={style.productParentLine} />
      {indexPosition > 0 && <div className={style.productVerticalLine} />}
    </div>
  );
}
