// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DghpqShuZR0VMAToBbhp{height:25px}.pf2yHUT9_3SYfkvHf2Xr{height:100%;color:#00a32e;cursor:pointer}.uVBmeGhW7PCqmxVLRhge{padding:30px}.VtNBNBgaeMud3FCB1LNl{display:flex;justify-content:center}.k2cEfx_5yS9Dgvv1kwZQ{width:260px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-align:center}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/upload.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,WAAA,CACA,aAAA,CACA,cAAA,CAGF,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,WAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA","sourcesContent":[".uploadBox {\n  height: 25px;\n}\n\n.uploadIcon {\n  height: 100%;\n  color: #00a32e;\n  cursor: pointer;\n}\n\n.formBox {\n  padding: 30px;\n}\n\n.formBtnLine {\n  display: flex;\n  justify-content: center;\n}\n\n.formUploadBtn {\n  width: 260px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadBox": `DghpqShuZR0VMAToBbhp`,
	"uploadIcon": `pf2yHUT9_3SYfkvHf2Xr`,
	"formBox": `uVBmeGhW7PCqmxVLRhge`,
	"formBtnLine": `VtNBNBgaeMud3FCB1LNl`,
	"formUploadBtn": `k2cEfx_5yS9Dgvv1kwZQ`
};
export default ___CSS_LOADER_EXPORT___;
