import axios from 'axios';

export const service = axios.create({
  baseURL: 'https://firefly.api.adobe.io',
});
export const tokenCache = JSON.parse(
  sessionStorage.getItem('adobeFireflyToken') ?? '{}'
);
export function setToken(id, token) {
  tokenCache.id = id;
  tokenCache.token = token;
  sessionStorage.setItem('adobeFireflyToken', JSON.stringify(tokenCache));
}

service.interceptors.request.use((config) => {
  if (tokenCache.id) {
    config.headers.set('X-Api-Key', tokenCache.id);
  }
  if (tokenCache.token) {
    config.headers.set('Authorization', `Bearer ${tokenCache.token}`);
  }
  return config;
});

// https://developer.adobe.com/firefly-services/docs/firefly-api/guides/api/upload_image/
export function postUploadImage(file, type = 'image/png') {
  return service.request({
    url: '/v2/storage/image',
    method: 'post',
    headers: {
      'Content-Type': type,
    },
    data: file,
  });
}

// https://developer.adobe.com/firefly-services/docs/firefly-api/guides/api/generative_fill/
export function postGenerativeFill(data) {
  if (data.size === 'square') {
    data.size = { width: 1024, height: 1024 };
  } else if (data.size === 'landscape') {
    data.size = { width: 1408, height: 1024 };
  } else if (data.size === 'portrait') {
    data.size = { width: 1024, height: 1408 };
  } else if (data.size === 'widescreen') {
    data.size = { width: 1792, height: 1024 };
  } else {
    throw new Error(`Invalid size ${data.size}`);
  }
  return service.request({
    url: '/v1/images/fill',
    method: 'post',
    data: data,
  });
}
