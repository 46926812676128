import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { putFileKey } from '../../apis/file';
import BalanceSvg from '../../assets/img/balance.svg';
import BallPng from '../../assets/img/ball.png';
import ClaritySvg from '../../assets/img/clarity.svg';
import ExcitementSvg from '../../assets/img/excitement.svg';
import Box from '../../components/box2.jsx';
import ColorCoding from '../../components/colorCoding.jsx';
import ImgDialog from '../../components/imgDialog.jsx';
import Legend from '../../components/legend.jsx';
import Loading from '../../components/loading.jsx';
import style from './appeal.module.scss';
import Table from './table.jsx';

function colorValue(val, thresholdFn) {
  return <ColorCoding colorValue={thresholdFn(val)}>{val}</ColorCoding>;
}

function Ball({ className = '', icon, title, content = '' }) {
  return (
    <div className={`${style.ball} ${className}`}>
      <img className={style.ballBg} src={BallPng} />
      <img className={style.ballIcon} src={icon} alt={title} />
      <div className={style.ballTitle}>{title}</div>
      <Box className={style.ballTooltip}>
        <div className={style.ballTooltipInner}>
          <div className={style.ballTooltipTitle}>{title}</div>
          {content}
        </div>
      </Box>
    </div>
  );
}

function ImageItem({ className = '', title = '', url, onClick }) {
  function onImgClick() {
    onClick && onClick(url);
  }
  return (
    <div className={className}>
      {/* <div className={style.imageItemTitle}>{title}</div> */}
      <div className={style.imageItemContent} onClick={onImgClick}>
        <img src={url} alt={title} />
      </div>
    </div>
  );
}

function PackageAiBody({ useNextBtn, context: ctx }) {
  // table 1
  const [column, setColumn] = useState([
    '',
    'Our Brand Current',
    'Our Brand New Design',
    'Competitor 1',
    'Competitor 2',
  ]);
  const [value, setValue] = useState([
    ['Design Excitement', 0, 0, 0, 0],
    ['Design Clarity', 0, 0, 0, 0],
    ['Design Symmetry', 0, 0, 0, 0],
  ]);
  // image
  const [data2, setData2] = useState([]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');
  function onImgClick(url) {
    setDialogVisible(true);
    setDialogUrl(url);
  }

  const [loading, setLoading] = useState(true);
  async function init() {
    try {
      const pp = ctx?.res?.predictPackage;
      if (!pp) return;
      const variants = ctx?.res?.variants;
      if (!variants) return;
      const aesImg = ctx?.res?.aesImg;
      if (!aesImg) return;

      const columns = [''];
      const values = [
        ['Design Excitement'],
        ['Design Clarity'],
        ['Design Symmetry'],
      ];
      const processAes = (name, aes) => {
        if (aes) {
          columns.push(name);
          values[0].push(
            colorValue(aes.exciting, (val) => {
              if (val > 40 && val < 60) return 3;
              else if (val > 25 && val < 75) return 2;
              else return 1;
            })
          );
          values[1].push(
            colorValue(aes.clear, (val) => {
              if (val > 75) return 3;
              else if (val > 50) return 2;
              else return 1;
            })
          );
          values[2].push(
            colorValue(aes.balance, (val) => {
              if (val > 75) return 3;
              else if (val > 60) return 2;
              else return 1;
            })
          );
        }
      };

      // ul brand
      const uld0v = variants[pp.ul_design0_variant0_id];
      processAes(`${pp.brand_ul} Current`, uld0v?.aesresult);
      const uld1v = variants[pp.ul_design1_variant0_id];
      processAes(`${pp.brand_ul} New Design`, uld1v?.aesresult);
      const uld2v = variants[pp.ul_design2_variant0_id];
      processAes(`${pp.brand_ul} New Design 2`, uld2v?.aesresult);
      const uld3v = variants[pp.ul_design3_variant0_id];
      processAes(`${pp.brand_ul} New Design 3`, uld3v?.aesresult);
      // c1 brand
      const c1d1v = variants[pp.c1_design1_variant0_id];
      processAes(`${pp.brand_c1}`, c1d1v?.aesresult);
      // c2 brand
      const c2d1v = variants[pp.c2_design1_variant0_id];
      processAes(`${pp.brand_c2}`, c2d1v?.aesresult);

      setColumn(columns);
      setValue(values);

      const data2 = [];
      const processValue = async (name, fileKey) => {
        if (!fileKey) return;
        const { data } = await putFileKey(fileKey);
        data2.push({ title: name, url: data.url });
      };

      // set ul data
      const uld0 = aesImg['current design'];
      await processValue(`${pp.brand_ul} Current`, uld0);
      const uld1 = aesImg['new design'];
      await processValue(`${pp.brand_ul} New Design`, uld1);
      setData2(data2);
    } catch (err) {
      console.error('init error', err);
      message.error('Failed to load data');
    }
    setLoading(false);
  }
  useEffect(() => {
    void init();
  }, [ctx]);

  return (
    <>
      {loading && <Loading />}
      <div className={style.box2}>
        <Ball
          icon={ExcitementSvg}
          title="Design Excitement"
          content="The ‘Excitement Score’ indicates how colorful a design is. It measures vividness and richness of the design. Scores range from 0 to 100. High scores mean the design is vibrant and exciting, low scores mean it’s calm and dull. A score between 25 and 75 is usually best."
        />
        <Ball
          icon={ClaritySvg}
          title="Design Clarity"
          content="The ‘Clarity Score’ measures the complexity of a design. It counts the regions in the image. More regions make the design complex and less clear. Too much complexity can hurt the design’s appeal. Scores range from 0 to 100. High scores mean the design is simple and clear. Low scores mean it’s complex and confusing. Below 25 is bad, 50-75 is good, and above 75 is excellent."
        />
        <Ball
          icon={BalanceSvg}
          title="Design Symmetry"
          content="The ‘Symmetry Score’ measures a design’s symmetry, which contributes to a design's appeal. This algorithm checks for color symmetry and intensity and gives a score ranging from 0 to 100. High scores mean the design is balanced and symmetrical. Low scores mean it’s unbalanced and asymmetrical. A score above 60 is considered good."
        />
      </div>
      <div className={style.box1}>
        <Table className={style.table} labels={column} values={value} />
        <div className={style.legendBox}>
          <Legend />
        </div>
      </div>
      {data2.map((item, index) => {
        return (
          <ImageItem
            key={`${item.title}-${index}`}
            className={style.box11Item}
            title={item.title}
            url={item.url}
            onClick={onImgClick}
          />
        );
      })}
      <div className={style.btnBox}>{useNextBtn && useNextBtn({})}</div>
      <ImgDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        src={dialogUrl}
      />
    </>
  );
}

export default PackageAiBody;
