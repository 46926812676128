import React from 'react';
import style from './box.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

function PackageBox({
  className = '',
  hasHeader = false,
  headerColorReverse = false,
  title,
  headerRight,
  children,
  loading = false,
}) {
  return (
    <div className={`${style.box} ${className}`}>
      <div
        className={`${style.boxCorner} ${style.boxCornerTop} ${style.boxCornerLeft}`}
      />
      <div
        className={`${style.boxCorner} ${style.boxCornerTop} ${style.boxCornerRight}`}
      />
      <div
        className={`${style.boxCorner} ${style.boxCornerBottom} ${style.boxCornerLeft}`}
      />
      <div
        className={`${style.boxCorner} ${style.boxCornerBottom} ${style.boxCornerRight}`}
      />
      <div className={style.boxInner}>
        {hasHeader && (
          <div
            className={`${style.boxHeader} ${
              headerColorReverse ? style.boxHeaderColorReverse : ''
            }`}
          >
            <div className={style.boxTitle}>{title}</div>
            {headerRight}
          </div>
        )}
        <div className={style.boxBody}>{children}</div>
        {loading && (
          <div className={style.boxLoading}>
            <div className={style.boxLoadingIcon}>
              <LoadingOutlined />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PackageBox;
