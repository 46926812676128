import { Select } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { getFilter } from '../../api/index.js';
import { VERTICAL_OPTIONS } from '../../components/filter/vertical.js';
import style from './filter.module.scss';

function Filter({ className, query, setQuery }) {
  const onClear = useCallback(() => {
    setQuery({});
  }, [setQuery]);

  const [marketOptions, setMarketOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const getOptions = useCallback(async () => {
    const { data: md } = await getFilter('market');
    const { data: cd } = await getFilter('category');
    if (md) {
      setMarketOptions(
        md.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
    if (cd) {
      setCategoryOptions(
        cd.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [setCategoryOptions, setMarketOptions]);
  useEffect(() => {
    void getOptions().catch((err) => {
      console.error('get options error', err);
    });
  }, [getOptions]);

  const onMarketChange = useCallback(
    (val) => {
      setQuery({
        ...query,
        market: val,
      });
    },
    [query, setQuery]
  );
  const onVerticalChange = useCallback(
    (val) => {
      setQuery({
        ...query,
        vertical: val,
      });
    },
    [query, setQuery]
  );
  const onCategoryChange = useCallback(
    (val) => {
      setQuery({
        ...query,
        category: val,
      });
    },
    [query, setQuery]
  );

  return (
    <div className={classNames(style.box1, className)}>
      <div className={style.box11}>
        <div className={style.box111}>
          <div className={style.icon}></div>
          <div className={style.text}>Filter</div>
        </div>
        <div className={style.box112}>
          <div className={style.btn} onClick={onClear}>
            Clear
          </div>
        </div>
      </div>
      <div className={style.box12}>
        <div className={style.label}>Market</div>
        <Select
          className={style.select}
          value={query.market}
          onChange={onMarketChange}
          options={marketOptions}
        />
        <div className={style.label}>Vertical</div>
        <Select
          className={style.select}
          value={query.vertical}
          onChange={onVerticalChange}
          options={VERTICAL_OPTIONS}
        />
        <div className={style.label}>Category</div>
        <Select
          className={style.select}
          value={query.category}
          onChange={onCategoryChange}
          options={categoryOptions}
        />
      </div>
    </div>
  );
}

export default Filter;
