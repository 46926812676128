import { ArrowRightOutlined } from '@ant-design/icons';
import { Checkbox, Col, message, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { putFileKey } from '../../apis/file';
import { postPredictPackage } from '../../apis/serve';
import HelpPng from '../../assets/img/help1.png';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 as Btn2 } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import EditableText from '../../components/editableText.jsx';
import Help from '../../components/help.jsx';
import Stage from '../../components/stage.jsx';
import Title from '../../components/title2.jsx';
import { uuid } from '../../utils/uuid';
import Design from './design.jsx';
import style from './index.module.scss';
import Loading from './loading.jsx';

function Info() {
  return (
    <div className={style.info}>
      <p>Please upload each pack design to be tested: </p>
      <ul>
        <li>File Type: PNG or JPG</li>
        <li>Background color: white</li>
        <li>Angle: front view</li>
        <li>
          Quality: Color and brightness of images of existing and new products
          should be comparable.
        </li>
        <li>Ensure the file name is not too long.</li>
      </ul>
      <Help className={style.infoHelp} hasBox={false}>
        <img src={HelpPng} alt="help" />
      </Help>
    </div>
  );
}

function useDesigns(context, onDesignUpload, showDesignTitle) {
  return context.asset.unilever.map((d, i) => {
    if (
      context.basic.businessObjective === 'Competitor Benchmarking' &&
      i === 0
    ) {
      return null;
    } else if (context.basic.businessObjective === 'Innovation' && i > 0) {
      return null;
    } else {
      return (
        <Col key={d.id} span={6}>
          <Design
            className={style.design}
            context={context}
            design={d}
            showTitle={showDesignTitle}
            onChange1={(fileData) => onDesignUpload('u', i, 0, fileData)}
            onChange2={(fileData) => onDesignUpload('u', i, 1, fileData)}
          />
        </Col>
      );
    }
  });
}

function buildVariant(i) {
  return { id: uuid(), name: `Variant ${i + 1}` };
}
function buildDesign(i) {
  let name = 'New Design';
  if (i === 0) {
    name = 'Current Design';
  } else if (i > 1) {
    name += ` ${i}`;
  }

  return {
    id: uuid(),
    name,
    variants: [buildVariant(0)],
  };
}

function PackageAiView() {
  const navigate = useNavigate();
  const [context, setContext] = useState({
    basic: {
      businessObjective: 'Redesign',
      numberOfOwnNewPackDesigns: 1,
      numberOfVariantsPerDesign: 1,
    },
    asset: {
      allowPublic: true,
      unilever: [buildDesign(0)],
      competitor1: buildDesign(0),
      competitor2: buildDesign(0),
    },
  });

  const complete = useMemo(() => {
    // check u
    for (let i = 0; i <= context.basic.numberOfOwnNewPackDesigns; i += 1) {
      if (
        i === 0 &&
        context.basic.businessObjective === 'Competitor Benchmarking'
      ) {
        continue;
      } else if (i > 0 && context.basic.businessObjective === 'Innovation') {
        continue;
      }
      // check all variant
      for (let j = 0; j < context.basic.numberOfVariantsPerDesign; j += 1) {
        if (!context.asset.unilever[i].variants[j].url) {
          return false;
        }
      }
    }

    // check c1
    for (let i = 0; i < context.basic.numberOfVariantsPerDesign; i += 1) {
      if (!context.asset.competitor1.variants[i].url) {
        return false;
      }
    }
    // check c2
    for (let i = 0; i < context.basic.numberOfVariantsPerDesign; i += 1) {
      if (!context.asset.competitor2.variants[i].url) {
        return false;
      }
    }

    return true;
  }, [context]);

  const [unileverBrandName, setUnileverBrandName] = useMemo(() => {
    return [
      context.basic?.unileverBrandName ?? 'Unilever Brand Name',
      (value) => {
        setContext((ctx) => ({
          ...ctx,
          basic: {
            ...ctx.basic,
            unileverBrandName: value,
          },
        }));
      },
    ];
  }, [context]);
  const [competitor1BrandName, setCompetitor1BrandName] = useMemo(() => {
    return [
      context.basic?.competitor1BrandName ?? 'Competitor 1 Brand Name',
      (value) => {
        setContext((ctx) => ({
          ...ctx,
          basic: {
            ...ctx.basic,
            competitor1BrandName: value,
          },
        }));
      },
    ];
  }, [context]);
  const [competitor2BrandName, setCompetitor2BrandName] = useMemo(() => {
    return [
      context.basic?.competitor2BrandName ?? 'Competitor 2 Brand Name',
      (value) => {
        setContext((ctx) => ({
          ...ctx,
          basic: {
            ...ctx.basic,
            competitor2BrandName: value,
          },
        }));
      },
    ];
  }, [context]);
  const [allowPublic, setAllowPublic] = useMemo(() => {
    return [
      context.asset?.allowPublic ?? true,
      (ev) => {
        setContext((ctx) => ({
          ...ctx,
          asset: {
            ...ctx.asset,
            allowPublic: ev.target.checked,
          },
        }));
      },
    ];
  }, [context]);
  const showDesignTitle = useMemo(() => {
    return context.basic.businessObjective === 'Redesign';
  });

  async function init() {
    const ctxs = sessionStorage.getItem('packageAiContext');
    if (!ctxs) {
      message.error('Please fill in the basic information first');
      navigate('/package-ai-screening-basic');
      return null;
    }
    const ctx = JSON.parse(ctxs);
    if (!ctx.asset) {
      ctx.asset = {
        allowPublic: true,
        unilever: [buildDesign(0)],
        competitor1: buildDesign(0),
        competitor2: buildDesign(0),
      };
      for (let i = 0; i < ctx.basic.numberOfOwnNewPackDesigns; i++) {
        ctx.asset.unilever.push(buildDesign(i + 1));
      }
      for (let i = 1; i < ctx.basic.numberOfVariantsPerDesign; i++) {
        ctx.asset.unilever.forEach((d) => {
          d.variants.push(buildVariant(i));
        });
        ctx.asset.competitor1.variants.push(buildVariant(i));
        ctx.asset.competitor2.variants.push(buildVariant(i));
      }
    } else {
      const processVariant = async (v) => {
        if (v.file) {
          const { data: vd } = await putFileKey(v.file);
          v.url = vd.url;
        }
        if (v.standardFile) {
          const { data: vd } = await putFileKey(v.standardFile);
          v.standardUrl = vd.url;
        }
        if (v.editedFile) {
          const { data: vd } = await putFileKey(v.editedFile);
          v.editedUrl = vd.url;
        }
      };
      for (const d of ctx.asset.unilever) {
        for (const v of d.variants) {
          await processVariant(v);
        }
      }
      for (const v of ctx.asset.competitor1.variants) {
        await processVariant(v);
      }
      for (const v of ctx.asset.competitor2.variants) {
        await processVariant(v);
      }
    }
    setContext(ctx);
  }
  useEffect(() => {
    init().catch((err) => {
      console.error('init error', err);
    });
  }, []);

  /**
   *
   * @param {"u"|"c1"|"c2"} to
   * @param {number} index
   * @param {number} variantIndex
   * @param {{std:string;stdUrl?:string;raw:string;rawUrl?:string}} fileData
   */
  function onDesignUpload(to, index, variantIndex, fileData) {
    const { raw, rawUrl, std, stdUrl } = fileData;
    setContext((ctx) => {
      const nc = { ...ctx };
      if (to === 'u') {
        nc.asset.unilever[index].variants[variantIndex].file = raw;
        nc.asset.unilever[index].variants[variantIndex].url = rawUrl;
        nc.asset.unilever[index].variants[variantIndex].standardFile = std;
        nc.asset.unilever[index].variants[variantIndex].standardUrl = stdUrl;
      } else if (to === 'c1') {
        nc.asset.competitor1.variants[variantIndex].file = raw;
        nc.asset.competitor1.variants[variantIndex].url = rawUrl;
        nc.asset.competitor1.variants[variantIndex].standardFile = std;
        nc.asset.competitor1.variants[variantIndex].standardUrl = stdUrl;
      } else if (to === 'c2') {
        nc.asset.competitor2.variants[variantIndex].file = raw;
        nc.asset.competitor2.variants[variantIndex].url = rawUrl;
        nc.asset.competitor2.variants[variantIndex].standardFile = std;
        nc.asset.competitor2.variants[variantIndex].standardUrl = stdUrl;
      }
      return nc;
    });
  }

  const [loading, setLoading] = useState(false);

  async function sendPP(ctx) {
    const pppData = {
      project_name: ctx.basic.projectName,
      business_obj: ctx.basic.businessObjective,
      category: ctx.basic.category,

      brand_ul: ctx.basic.unileverBrandName,
      brand_c1: ctx.basic.competitor1BrandName,
      brand_c2: ctx.basic.competitor2BrandName,

      is_share: ctx.asset.allowPublic,

      use_template: ctx.useTemplate ? 'true' : 'false',
    };

    ctx.asset.unilever.forEach((a, idx) => {
      a.variants.forEach((v, idx2) => {
        const key = `ul_design${idx}_variant${idx2}`;
        pppData[key] = v.file;
        const standardKey = `${key}_standard`;
        pppData[standardKey] = v.standardFile;
      });
    });
    ctx.asset.competitor1.variants.forEach((v, idx) => {
      const key = `c1_design1_variant${idx}`;
      pppData[key] = v.file;
      const standardKey = `${key}_standard`;
      pppData[standardKey] = v.standardFile;
    });
    ctx.asset.competitor2.variants.forEach((v, idx) => {
      const key = `c2_design1_variant${idx}`;
      pppData[key] = v.file;
      const standardKey = `${key}_standard`;
      pppData[standardKey] = v.standardFile;
    });

    const { data: pppRes } = await postPredictPackage(pppData);
    if (!pppRes) {
      message.warning('Post predict package failed, null response data');
      return null;
    }

    return pppRes;
  }

  async function onContinue() {
    if (!complete) return;

    if (loading) return;
    setLoading(true);
    try {
      const ctx = { ...context };
      // send predict package
      const pppRes = await sendPP(ctx);
      // update ctx
      ctx.res = { predictPackage: pppRes };

      sessionStorage.setItem('packageAiContext', JSON.stringify(ctx));
      navigate('/package-ai-screening-aoi');
    } catch (err) {
      console.error('send predict package error', err);
      message.error('Send predict package failed');
      setLoading(false);
    }
  }

  return (
    <Container>
      <div className={style.containerInner}>
        <Stage stage={2} />
        <Box title="Upload Pack Design Images" className={style.box}>
          <div className={style.form}>
            <Info />
            {!(
              context.basic.businessObjective === 'Competitor Benchmarking' &&
              context.basic.numberOfOwnNewPackDesigns === 0
            ) && (
              <>
                <Title>
                  <EditableText
                    value={unileverBrandName}
                    onChange={setUnileverBrandName}
                  />
                </Title>
                <Row gutter={12}>
                  {useDesigns(context, onDesignUpload, showDesignTitle)}
                </Row>
              </>
            )}
            <Row gutter={12}>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Title>
                      <EditableText
                        value={competitor1BrandName}
                        onChange={setCompetitor1BrandName}
                      />
                    </Title>
                    <Design
                      className={style.design}
                      context={context}
                      design={context.asset.competitor1}
                      showTitle={false}
                      onChange1={(fileData) =>
                        onDesignUpload('c1', 0, 0, fileData)
                      }
                      onChange2={(fileData) =>
                        onDesignUpload('c1', 0, 1, fileData)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Title>
                      <EditableText
                        value={competitor2BrandName}
                        onChange={setCompetitor2BrandName}
                      />
                    </Title>
                    <Design
                      className={style.design}
                      context={context}
                      design={context.asset.competitor2}
                      showTitle={false}
                      onChange1={(fileData) =>
                        onDesignUpload('c2', 0, 0, fileData)
                      }
                      onChange2={(fileData) =>
                        onDesignUpload('c2', 0, 1, fileData)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={style.btnsBox}>
            <Btn2
              className={style.btn2}
              onClick={onContinue}
              suffix={<ArrowRightOutlined></ArrowRightOutlined>}
              disabled={!complete}
            >
              CONTINUE
            </Btn2>
            <Checkbox checked={allowPublic} onChange={setAllowPublic}>
              Publish my testing results in the Sous-Chef library (visible for
              others). Deselect it for keeping results only visible to you
            </Checkbox>
          </div>
        </Box>
      </div>
      {loading && <Loading />}
    </Container>
  );
}

export default PackageAiView;
