import React, { useEffect, useState } from 'react';
// import LeftSidebarCollapsible from '../../../components/left-navigation/left-navigation.jsx';
import { ConfigProvider, theme, App, Button, Form, notification } from 'antd';
import UserManagementTable from '../components/user-management-tables.jsx';
import EditCreateUserModal from '../components/edit-create-user.jsx';
import { getUserList, createUser } from '../api/request.js';
import { Navigate } from 'react-router-dom';

// let message;

const UserManagementPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);  
  const [form] = Form.useForm();  
  const showModal = () => {  
    setIsModalVisible(true);  
  };  
  
  const handleFinish = () => {  
    form  
      .validateFields()  
      .then(values => {  
        console.log('Form values: ', values);  
        createUser(JSON.stringify(values)).then((response)=>{
          if (response.status === 'Success') {
            notification.open({
              message: 'successfully created user',
              description: 'welcome new user:' + values.username,
              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            fetchData();

          } else {
            notification.error({
              message: 'Fail to create user',
            });
            console.error('error creating');
          };
        });
        setIsModalVisible(false);  
      })  
      .catch(info => {  
        console.log('Validate Failed:', info);  
      });  
  };  
  
  const [userList, setUserList] =  useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ loading, setLoading ] = useState(false);
  const user = JSON.parse(sessionStorage.getItem('user'));
  
  // default page size 10
  const pageSize = 10;
  
  const fetchData = async () => {  
    try { 
      setLoading(true); 
      const result = await getUserList(currentPage, pageSize);  
      if (result) {  
        setUserList(result.data);  
        setTotal(result.total);  
      }  
    } catch (error) {  
      console.error('Error fetching user list in useEffect:', error);  
    }
    setLoading(false);    
  };  

  useEffect(()=>{
    fetchData();
  }, [currentPage]);

  const handlePageChange = (e) => {
    console.log(e);
    setCurrentPage(e.current);
  };

  const handleDownload = () => {
    console.log('Downloading');
  };

  if (!user || !user.is_supervisor) {
    return <Navigate to="/navbar" replace />;
  }

  return (
    <>
      <div className="user-management-page">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#00a32e',
              colorError: '#eb0000',
              colorSuccess: '#06ca3d',
              fontFamily: 'unilever-shilling-regular',
            },
            algorithm: theme.darkAlgorithm,
          }}
        >
          <Button type="primary" onClick={showModal}>New</Button>
          <Button onClick={handleDownload}>Download</Button>
          <Button onClick={fetchData} loading={loading}>
            Refresh
          </Button>
          <App>
            <UserManagementTable
              fetchData={fetchData}
              userList={userList}
              total={total}
              currentPage={currentPage}
              pageSize={pageSize}
              loading={loading}
              handlePageChange={handlePageChange}
            />
            <EditCreateUserModal
              title="New User"
              isEditing={isModalVisible}
              setIsEditing={setIsModalVisible}
              form={form}
              handleFinish={handleFinish}
            />
          </App>
        </ConfigProvider>
      </div>
    </>
  );
};   

export default UserManagementPage;