import classNames from 'classnames';
import * as echarts from 'echarts';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import style from './chart.module.scss';
import { getProduct } from '../../api/index.js';

const COLORS = ['#026A1F', '#00A889', '#FFF45B', '#EB6878'];
const COLORS2 = [
  ['#072B08', '#013B02', '#026A1F', '#00A32E', '#7FC268', '#B3D365'],
  ['#01504E', '#068B87', '#12B5B1', '#20C9B7', '#3FF0DD', '#7DD0C2'],
  ['#A73615', '#EC6842', '#F09049', '#F7B551', '#F6C335', '#FFF45B'],
  ['#443F79', '#4C55A6', '#7160C0', '#EB68C2', '#EB6878', '#AC68EB'],
];

const GENERATION_OPTIONS = [
  { label: 'Gen X', value: 'Gen X' },
  { label: 'Millennials', value: 'Millennials' },
  { label: 'Gen Z', value: 'Gen Z' },
  { label: 'Baby Boomers', value: 'Baby Boomers' },
];
const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
const USER_VS_NONE_USER_OPTIONS = [
  { label: 'Users', value: 'Users' },
  { label: 'Non-Users', value: 'Non-Users' },
];
const SOCIAL_CLASS_OPTIONS = [
  { label: 'Low Income', value: 'Low Income' },
  { label: 'Middle Income', value: 'Middle Income' },
  { label: 'High Income', value: 'High Income' },
];
const SUB_FILTERS = {
  generation: GENERATION_OPTIONS,
  gender: GENDER_OPTIONS,
  user_vs_nouser: USER_VS_NONE_USER_OPTIONS,
  social_class: SOCIAL_CLASS_OPTIONS,
};

function processName(name) {
  return name.split(' ')[0];
}

function Chart({ className, title, data = [], kkey }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  const useOption = useCallback(() => {
    if (!chartRef.current) return;
    let cc = chart;
    if (!cc) {
      cc = echarts.init(chartRef.current);
      setChart(cc);
    }

    const option = {
      textStyle: {
        fontSize: 6,
        color: '#fff',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        textStyle: {
          fontSize: 8,
        },
      },
      grid: {
        top: '9%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            fontSize: 6,
          },
          data: data.map((e) => processName(e.display_name)),
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      color: COLORS,
      series: [
        {
          name: title,
          type: 'bar',
          data: data.map((e, index) => {
            return {
              value: e[kkey],
              itemStyle: {
                color: COLORS[index % COLORS.length],
              },
            };
          }),
        },
      ],
    };

    cc.setOption(option);
  }, [chartRef, chartRef.current, data]);
  useEffect(() => {
    useOption && useOption();
  }, [useOption]);

  return (
    <div className={classNames(style.chartBox, className)}>
      <div className={style.chartTitle}>
        <div className={style.icon} />
        <span className={style.text}> {title}</span>
      </div>
      <div ref={chartRef} className={style.chart} />
    </div>
  );
}

function Chart2({ className, title, data = [], subData, kkey }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  const useOption = useCallback(() => {
    if (!chartRef.current) return;
    let cc = chart;
    if (!cc) {
      cc = echarts.init(chartRef.current);
      setChart(cc);
    }

    const dd = [];
    let i = 0;
    for (const [key, value] of subData) {
      const d = {
        name: key,
        type: 'bar',
        stack: 'total',
        data: [],
      };

      let j = 0;
      for (const dd of data) {
        const colors = COLORS2[j % COLORS.length];
        const color = colors[i % colors.length];
        console.log('color', color);
        const p = value.get(dd.sn);
        if (p) {
          d.data.push({
            value: p[kkey],
            itemStyle: {
              color: color,
            },
          });
        } else {
          d.data.push(0);
        }
        j += 1;
      }

      dd.push(d);
      i += 1;
    }

    const option = {
      textStyle: {
        fontSize: 6,
        color: '#fff',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        textStyle: {
          fontSize: 8,
        },
      },
      grid: {
        top: '9%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            fontSize: 6,
          },
          data: data.map((e) => processName(e.display_name)),
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      color: COLORS,
      series: dd,
    };

    cc.setOption(option);
  }, [chartRef, chartRef.current, data, subData]);
  useEffect(() => {
    useOption && useOption();
  }, [useOption]);

  return (
    <div className={classNames(style.chartBox, className)}>
      <div className={style.chartTitle}>
        <div className={style.icon} />
        <span className={style.text}> {title}</span>
      </div>
      <div ref={chartRef} className={style.chart} />
    </div>
  );
}

function ChartContainer({ className, children }) {
  return (
    <div className={classNames(style.box1, className)}>
      <div className={style.box11}>{children}</div>
    </div>
  );
}

function ChartView({ className, data = [], keys = [], subFilter }) {
  const ids = useMemo(() => {
    return data.map((e) => e.sn);
  }, [data]);
  const options = useMemo(() => {
    if (!subFilter) return [];
    return SUB_FILTERS[subFilter] ?? [];
  }, [subFilter]);
  const [subData, setSubData] = useState(new Map());

  const getData2 = useCallback(async () => {
    if (!ids.length) return;
    if (!options.length) return;
    try {
      const l = new Map();
      for (const o of options) {
        const sp = {
          [subFilter]: [o.value],
        };
        const { data: pd } = await getProduct(
          undefined,
          sp,
          undefined,
          undefined,
          undefined,
          ids
        );
        const ppd = new Map();
        pd.forEach((p) => {
          ppd.set(p.sn, p);
        });
        l.set(o.value, ppd);
      }
      setSubData(l);
    } catch (err) {
      console.error('get data 2 error', err);
    }
  }, [ids, options]);
  useEffect(() => {
    void getData2();
  }, [subFilter]);

  if (!options.length) {
    return (
      <ChartContainer className={className}>
        {keys.map((key) => {
          return (
            <Chart
              key={`${key.key}1`}
              title={key.title}
              data={data}
              kkey={key.key}
            />
          );
        })}
      </ChartContainer>
    );
  } else {
    return (
      <ChartContainer className={className}>
        {keys.map((key) => {
          return (
            <Chart2
              key={`${key.key}2`}
              title={key.title}
              data={data}
              subData={subData}
              kkey={key.key}
              ids={ids}
              options={options}
              subKey={subFilter}
            />
          );
        })}
      </ChartContainer>
    );
  }
}

export default ChartView;
