// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EBgsFYJ9zxzk0KUkha1W{display:flex;align-items:center}.rNzetEcWo3Fmz8yxC1me{margin-right:15px;font-size:16px;font-weight:900;line-height:27.88px;color:rgba(255,255,255,.5019607843);transition:all .4s;cursor:pointer}.rNzetEcWo3Fmz8yxC1me.exkXQi3Yb0zzVBodx81h{font-size:20px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/components/box-tab.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,mCAAA,CACA,kBAAA,CACA,cAAA,CAEA,2CACE,cAAA,CACA,UAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n}\n\n.option {\n  margin-right: 15px;\n  font-size: 16px;\n  font-weight: 900;\n  line-height: 27.88px;\n  color: #ffffff80;\n  transition: all 0.4s;\n  cursor: pointer;\n\n  &.active {\n    font-size: 20px;\n    color: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `EBgsFYJ9zxzk0KUkha1W`,
	"option": `rNzetEcWo3Fmz8yxC1me`,
	"active": `exkXQi3Yb0zzVBodx81h`
};
export default ___CSS_LOADER_EXPORT___;
