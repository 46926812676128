import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getProduct } from '../../api';
import Container from '../../components/container/index.jsx';
import style from './index.module.scss';
import Tree from './tree.jsx';

function PackageAiView() {
  const [zoomScale, setZoomScale] = useState(1);

  const zoomIn = useCallback(() => {
    setZoomScale((z) => Math.min(z * 1.1, 1.2));
  }, [setZoomScale]);
  const zoomOut = useCallback(() => {
    setZoomScale((z) => Math.max(z / 1.1, 0.5));
  }, [setZoomScale]);

  const [searchParams] = useSearchParams();
  const [brand, setBrand] = useState(searchParams.get('brand'));
  const [data, setData] = useState([]);
  const getData = useCallback(async () => {
    const { data: pd } = await getProduct({ brand: [brand] });
    if (pd) setData(pd);
  }, [setData, brand]);
  useEffect(() => {
    getData().catch((err) => {
      console.error('get product data error', err);
    });
  }, [brand]);

  return (
    <Container>
      <div className={style.box1}>
        <div className={style.box11}>
          <ZoomInOutlined className={style.zoomBtn} onClick={zoomIn} />
          <ZoomOutOutlined className={style.zoomBtn} onClick={zoomOut} />
        </div>
        <div className={style.box12}>
          <div className={style.infoText}>
            The aggregate results are based solely on core product test <br />
            results. Please drill down to view the details
          </div>
        </div>
        <div className={style.box13}>
          <Tree
            zoom={zoomScale}
            data={data}
            brand={brand}
            setBrand={setBrand}
          />
        </div>
      </div>
    </Container>
  );
}

export default PackageAiView;
