// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ze3fvAmLBgjK9uyw4B73{height:85%;width:90%;margin:5%;border-radius:15px;background-color:#fff;overflow:hidden;display:flex;justify-content:center;transform-origin:center}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/edit/editor.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,SAAA,CACA,SAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CACA,uBAAA","sourcesContent":[".editorBox {\n  height: 85%;\n  width: 90%;\n  margin: 5%;\n  border-radius: 15px;\n  background-color: #fff;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  transform-origin: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorBox": `Ze3fvAmLBgjK9uyw4B73`
};
export default ___CSS_LOADER_EXPORT___;
