import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Switch } from 'antd';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import exportToExcel from '../../api/export.js';
import { getProduct } from '../../api/index.js';
import Container from '../../components/container/index.jsx';
import { getVerticalOptionValue } from '../../components/filter/vertical.js';
import ImgDialog from '../../components/img-dialog/index.jsx';
import { useVerticalRank } from '../../hooks/vertical.js';
import Chart from './chart.jsx';
import Filter from './filter.jsx';
import Header from './header.jsx';
import style from './index.module.scss';

function Divider({ className }) {
  return <div className={classNames(style.divider, className)} />;
}

function PackageAiView() {
  // loading
  const [loading, setLoading] = useState(false);

  // query
  const [query, setQuery] = useState({});
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const s = Object.fromEntries(searchParams);
    setQuery(s);
  }, [searchParams]);

  // data
  const [data, setData] = useState([]);
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const cl = [];
      if (query.category) {
        cl.push(query.category);
      }
      if (query.vertical) {
        cl.push(...getVerticalOptionValue(query.vertical));
      }

      const cm = new Map();
      cl.forEach((c) => {
        cm.set(c, true);
      });
      const cc = Array.from(cm.keys());

      const { data: dd } = await getProduct({
        market: query.market ? [query.market] : [],
        category: cc.length ? cc : [],
      });

      setData(dd);
    } catch (err) {
      console.error('get data error', err);
    }
    setLoading(false);
  }, [query, setData]);
  useEffect(() => {
    void getData();
  }, [getData]);

  const { getVerticalRank } = useVerticalRank(data);

  // build data
  const data2 = useMemo(() => {
    let totalBrand = 0;
    let totalAppeal = 0;
    let totalUpi = 0;

    let maxBrand = 0;
    let maxAppeal = 0;
    let maxUpi = 0;

    const d = data.map((e) => {
      totalBrand += e.brand_logo_visibility;
      totalAppeal += e.appeal;
      totalUpi += e.upi;

      maxBrand = Math.max(maxBrand, e.brand_logo_visibility);
      maxAppeal = Math.max(maxAppeal, e.appeal);
      maxUpi = Math.max(maxUpi, e.upi);

      return {
        name: e.display_name,
        key: `${e.market}-${e.sn}`,
        market: e.market,
        sn: e.sn,
        brand: e.brand_logo_visibility,
        brandRank: getVerticalRank(
          e.category,
          'brand_logo_visibility',
          e.market,
          e.sn
        ),
        appeal: e.appeal,
        appealRank: getVerticalRank(e.category, 'appeal', e.market, e.sn),
        upi: e.upi,
        upiRank: getVerticalRank(e.category, 'upi', e.market, e.sn),
      };
    });

    const avgBrand = totalBrand / d.length;
    const avgAppeal = totalAppeal / d.length;
    const avgUpi = totalUpi / d.length;

    return d.map((e) => {
      return {
        ...e,
        brand2: (e.brand / avgBrand) * 100.0,
        brand3: (e.brand / maxBrand) * 100.0,
        appeal2: (e.appeal / avgAppeal) * 100.0,
        appeal3: (e.appeal / maxAppeal) * 100.0,
        upi2: (e.upi / avgUpi) * 100.0,
        upi3: (e.upi / maxUpi) * 100.0,
      };
    });
  }, [data, getVerticalRank]);

  // sort, brand, appeal, upi
  const [sortBy, setSortBy] = useState('brand');
  const [sortOrder, setSortOrder] = useState('asc');

  const sortData = useMemo(() => {
    return data2.sort((a, b) => {
      let x = a[sortBy];
      let y = b[sortBy];
      if (sortOrder === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    });
  }, [data2, sortBy, sortOrder]);

  const [showValue, setShowValue] = useState(true);

  const onSaveExcel = useCallback(() => {
    exportToExcel(
      'Pack AI Competitive Landscape',
      sortData.map((e) => {
        return {
          Market: e.market,
          'Product Name': e.name,
          'Brand Visibility': e.brand,
          Appeal: e.appeal,
          'Unpriced Purchase Intent': e.upi,
        };
      })
    );
  }, [sortData]);

  const [chartRef, setChartRef] = useState(null);
  const onSavePng = useCallback(() => {
    if (!chartRef) return;
    html2canvas(chartRef).then((canvas) => {
      const img = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = img;
      a.download = 'Pack AI Competitive Landscape.png';
      a.click();
    });
  }, [chartRef]);

  const onMenu = useCallback(
    ({ key }) => {
      if (key === '2') {
        onSavePng();
      } else if (key === '3') {
        onSaveExcel();
      }
    },
    [onSaveExcel]
  );

  // image dialog
  const [imgDialogVisible, setImgDialogVisible] = useState(false);
  const [imgDialogSrc, setImgDialogSrc] = useState();
  const [imgDialogAlt, setImgDialogAlt] = useState();
  const onImgClick = useCallback(
    (src, alt) => {
      setImgDialogSrc(src);
      setImgDialogAlt(alt);
      setImgDialogVisible(true);
    },
    [setImgDialogSrc, setImgDialogAlt, setImgDialogVisible]
  );

  return (
    <Container loading={loading}>
      <div className={style.box1}>
        <Filter query={query} setQuery={setQuery} />
        <Divider className={style.box12} />
        <div className={style.box13}>
          <div className={style.box131}>
            <div className={style.item}>
              <Header
                text="Brand Visibility"
                tip="This metric measures the probability of the brand logo being noticed in the initial 3-5 seconds of looking at the pack design. It is based on predictive eye-tracking as measured via the 3M VAS algorithm."
                sort={sortBy === 'brand' ? sortOrder : undefined}
                onSortClick={() => {
                  setSortBy('brand');
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                }}
              />
              <div
                className={classNames(style.itemBox1, {
                  [style.itemBox1Active]: sortBy === 'brand',
                })}
              ></div>
            </div>
            <Divider />
            <div className={style.item}>
              <Header
                text="Appeal"
                tip="This metric measures the rate of consumers who chose the pack as most appealing within its category shelf context. It is derived from consumer survey data."
                sort={sortBy === 'appeal' ? sortOrder : undefined}
                onSortClick={() => {
                  setSortBy('appeal');
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                }}
              />
              <div
                className={classNames(style.itemBox1, {
                  [style.itemBox1Active]: sortBy === 'appeal',
                })}
              ></div>
            </div>
            <Divider />
            <div className={style.item}>
              <Header
                text="Unpriced Purchase Intent"
                tip="This metric measures the rate of respondents who are moderately and very likely to purchase the product based on consideration of the design alone. It is based on consumer survey data."
                sort={sortBy === 'upi' ? sortOrder : undefined}
                onSortClick={() => {
                  setSortBy('upi');
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                }}
              />
              <div
                className={classNames(style.itemBox1, {
                  [style.itemBox1Active]: sortBy === 'upi',
                })}
              ></div>
            </div>
          </div>
          <div className={style.box132}>
            <Chart
              setRef={setChartRef}
              className={style.box1321}
              data={sortData}
              showValue={showValue}
              onImageClick={onImgClick}
            />
          </div>
        </div>
      </div>
      <div className={style.box2}>
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <span className={style.menu}>
                    <span className={style.text}>Show Data Labels</span>
                    <Switch
                      className={style.switch}
                      checked={showValue}
                      onChange={(checked) => setShowValue(checked)}
                    />
                  </span>
                ),
              },
              {
                key: '2',
                label: 'Save as PNG',
              },
              {
                key: '3',
                label: 'Export as Excel',
              },
            ],
            onClick: onMenu,
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <MoreOutlined className={style.icon} />
        </Dropdown>
      </div>
      <ImgDialog
        visible={imgDialogVisible}
        setVisible={setImgDialogVisible}
        src={imgDialogSrc}
        alt={imgDialogAlt}
      />
    </Container>
  );
}

export default PackageAiView;
