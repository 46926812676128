import { App, ConfigProvider, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import BgPng from './assets/img/img1.png';
import TitlePng from './assets/img/title.png';
import BgMp4 from './assets/video/bg.mp4';
import HelpMp4 from './assets/video/help.mp4';
import Box from './components/box/index.jsx';
import Btn from './components/btn.jsx';
import style from './index.module.scss';

function PackageAiView() {
  // eslint-disable-next-line no-unused-vars
  const [listVisible, setListVisible] = useState(true);

  const list = [
    // { text: 'Competitive Landscape', url: '/package-ai-performance' },
    // { text: 'Inspiration', url: '/package-ai-inspiration' },
    // { text: 'Line-Ex Edi', url: '/package-ai-generation' },
    { text: 'Screening', url: '/package-ai-screening-basic' },
  ];

  useEffect(() => {
    sessionStorage.removeItem('packageAiContext');
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <div className={style.view}>
          <video
            className={style.viewBg}
            autoPlay={true}
            loop={true}
            muted={true}
            controls={false}
          >
            <source src={BgMp4} type="video/mp4" />
            <img className={style.viewBg} src={BgPng} />
          </video>
          <div className={style.box1}>
            {/* title */}
            <img
              className={style.viewTitleImg}
              src={TitlePng}
              alt="Unmissable Pack AI"
            />
            {/* help */}
            <Box className={style.viewHelp}>
              <video className={style.viewHelpVideo} controls={true}>
                <source src={HelpMp4} />
              </video>
            </Box>
            {/* info */}
            <div className={style.infoBox}>
              This module is available for CMI use only.
              <br />
              Please contact your local CMI partner to conduct the Pack
              Screening test.
            </div>
            {/* route list */}
            {listVisible && (
              <div className={style.viewList}>
                {list.map((item) => {
                  return (
                    <Btn key={item.text} href={item.url}>
                      {item.text}
                    </Btn>
                  );
                })}
              </div>
            )}
          </div>
          {/* info */}
        </div>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
