import { App, ConfigProvider, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import BgPng from './assets/img/img1.png';
import TitlePng from './assets/img/title.png';
import BgMp4 from './assets/video/bg.mp4';
import Btn from './components/btn.jsx';
import style from './index.module.scss';

function PackageAiView() {
  const [listVisible, setListVisible] = useState(false);

  const list = [
    { text: 'Competitive Landscape' },
    { text: 'Inspiration' },
    { text: 'Line-Ex Editor' },
    { text: 'Screening', url: '/package-ai-screening-basic' },
  ];
  function onTitleClick() {
    setListVisible(true);
  }

  useEffect(() => {
    sessionStorage.removeItem('packageAiContext');
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <div className={style.view}>
          <video
            className={style.viewBg}
            autoPlay={true}
            loop={true}
            muted={true}
            controls={false}
          >
            <source src={BgMp4} type="video/mp4" />
            <img className={style.viewBg} src={BgPng} />
          </video>
          <img
            className={`${style.viewTitleImg} ${
              listVisible ? style.viewTitleImg1 : ''
            }`}
            src={TitlePng}
            alt="Unmissable Pack AI"
          />
          {/* btn */}
          <div
            className={style.btn}
            style={{ display: listVisible ? 'none' : '' }}
            onClick={onTitleClick}
          >
            <div className={style.btnC1} />
            <div className={style.btnC2} />
            <div className={style.btnText}>GO</div>
          </div>
          {/* route list */}
          <div
            className={style.viewList}
            style={{ display: listVisible ? '' : 'none' }}
          >
            {list.map((item) => {
              return (
                <Btn key={item.text} href={item.url}>
                  {item.text}
                </Btn>
              );
            })}
          </div>
        </div>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
