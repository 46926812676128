// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ijsxLUhEieGFZgDiPed9{height:85%;width:90%;margin:5%;border-radius:15px;background-color:#fff;overflow:hidden;display:flex;justify-content:center}.wAtUAcSisEYTTLWNpQwx{position:relative}.lCciAiidfT6rt9PfXejV{position:absolute;z-index:2;transform:translateY(-100%)}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/editor.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,SAAA,CACA,SAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,2BAAA","sourcesContent":[".editorBox {\n  height: 85%;\n  width: 90%;\n  margin: 5%;\n  border-radius: 15px;\n  background-color: #fff;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n}\n\n.editorInner {\n  position: relative;\n}\n\n.editorDeleteBtn {\n  position: absolute;\n  z-index: 2;\n  transform: translateY(-100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorBox": `ijsxLUhEieGFZgDiPed9`,
	"editorInner": `wAtUAcSisEYTTLWNpQwx`,
	"editorDeleteBtn": `lCciAiidfT6rt9PfXejV`
};
export default ___CSS_LOADER_EXPORT___;
