export const VERTICAL_MAP = {
  'Bouillon & Seasoning': ['Broth/Bouillon', 'Seasonings'],
  'Mini meals': [
    'Instant noodles & easy meals',
    'Flavored Rice/Pasta Sides',
    'Mac & Cheese',
  ],
  Condiments: [
    'Mayonnaise',
    'Condiments/Variety Sauce',
    'Mustard',
    'Ketchup',
    'Barbecue Sauce',
    'Flavored Mayo',
    'Flavoured Mayo',
    'Better-for-You Mayo',
  ],
  'Lifestyle Nutrition': ['Granola & Nutrition Bars', 'Nutritional powders'],
  Others: ['Ready Meals'],
};

export const VERTICAL_OPTIONS = Object.keys(VERTICAL_MAP).map((item) => ({
  label: item,
  value: item,
}));

export function getVerticalOptionValue(val = '') {
  return VERTICAL_MAP[val] ?? [];
}

export function findVertical(category) {
  for (const [key, value] of Object.entries(VERTICAL_MAP)) {
    if (value.includes(category)) {
      return key;
    }
  }
  return 'Others';
}
