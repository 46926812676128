import React, { useRef, useState, useEffect } from 'react';
import style from './style.module.scss';
import { App, Table, Button, Space, Input, Modal, Form, InputNumber } from 'antd';
import { LoadingOutlined, SearchOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { getProjectId, postProjectId, putProjectId, deleteProjectId, celeryTask } from '../../api/request.js';

const { Search } = Input;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: '#00FF00'
    }}
    spin
  />
);

const ProjectIdPage = () => {

  const staticFunction = App.useApp();
  const message = staticFunction.message;

  // table
  const [tableTotalData, setTableTotalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const paginateChange = (pagination) => {
    const {current, pageSize} = pagination;
    const dataList = tableTotalData.filter((item, index) => index >= (current - 1) * pageSize && index < current * pageSize);
    setTableData(dataList);
    setCurrent(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    paginateChange({current: 1, pageSize: pageSize});
  }, [pageSize]);

  useEffect(() => {
    paginateChange({current: 1, pageSize: pageSize});
  }, [tableTotalData]);

  const fetchTableDataFn = (params) => {
    setTableLoading(true);
    getProjectId(params)
      .then(res => {
        const resData = res.data?.map((item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        });
        setTableTotalData(Array.isArray(resData) ? resData : []);
        setTotal(Array.isArray(resData) ? resData.length : 0);
      })
      .catch(err => {console.error(err);})
      .finally(() => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    fetchTableDataFn();
  }, []);

  // search
  const [searchDefaultValue, setSearchDefaultValue] = useState('');
  const searchChange = (val) => {
    setSearchDefaultValue(val);
  };
  const onSearch = (val) => {
    if (tableLoading) return;
    const params = {
      title: val
    };
    fetchTableDataFn(params);
  };

  const formRef = useRef(null);
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const [modalTitle, setModalTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    project_id: '',
    title: '',
    topic: '',
    description: '',
    country: ''
  });

  // 更新当剝页数杮
  const updateCurrentPageData = (data, del) => {
    const index = tableTotalData.findIndex(item => item.id === data.id);
    if (del) {
      tableTotalData.splice(index, 1);
    } else {
      tableTotalData[index] = {
        ...tableTotalData[index],
        ...data
      };
    }
    const dataList = tableTotalData.filter((item, index) => index >= (current - 1) * pageSize && index < current * pageSize);
    setTableData(dataList);
  };

  // 编辑
  const [editItem, setEditItem] = useState(null);
  const editFn = (item) => {
    setModalTitle('Edit Project_Id');
    setEditItem(item);
    setIsModalOpen(true);
    setInitialValues(item);
  };

  // 删除
  const deleteFn = async (value) => {
    try {
      updateCurrentPageData({...value, _loading: true});
      await deleteProjectId({id: value.id});
      updateCurrentPageData({...value}, true);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Index',
      dataIndex: 'index',
      width: '100px',
      align: 'center',
    },
    {
      title: 'Project ID',
      dataIndex: 'project_id',
      width: '200px',
    },
    {
      title: 'title',
      dataIndex: 'title',
      width: '200px',
    },
    {
      title: 'topic',
      dataIndex: 'topic',
      width: '200px',
    },
    {
      title: 'description',
      dataIndex: 'description',
    },
    {
      title: 'country',
      dataIndex: 'country',
      width: '200px',
      align: 'center',
    },
    {
      title: 'create time',
      dataIndex: 'created_at',
      width: '200px',
      align: 'center',
    },
    {
      title: 'handle',
      width: '300px',
      align: 'center',
      render: (_, item) => {
        return (<Space size="middle">
          <Button disabled={item['_loading']} size='small' onClick={() => editFn(item)}>edit</Button>
          <Button loading={item['_loading']} size='small' onClick={() => deleteFn(item)}>delete</Button>
        </Space>
        );
      },
    }
  ];

  const rowClassName = (record) => {
    return editItem && editItem.id === record.id ? 'editing' : '';
  };

  // 新增
  const addFn = () => {
    setModalTitle('Add Project_Id');
    setIsModalOpen(true);
    setEditItem(null);
    setInitialValues({
      project_id: '',
      title: '',
      topic: '',
      description: '',
      country: ''
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const submitFn = () => {
    formRef.current.submit();
  };

  const onFinish = async (values) => {
    setSubmitLoading(true);
    try {
      if (editItem) {
        const data = {
          ...editItem,
          ...values
        };
        const res = await putProjectId(data);
        updateCurrentPageData(res.data);
        message.success('Success');
        cancelFn();
      } else {
        if (tableTotalData.find(item => item.project_id === values.project_id)) {
          message.error(`project_id: ${values.project_id} already exist`);
        } else {
          const res = await postProjectId(values);
          const list = [
            res.data,
            ...tableTotalData
          ].map((item, index) => {
            return {
              ...item,
              index: index + 1
            };
          });
          setTableTotalData(list);
          setTotal(list.length);
          message.success('Success');
          cancelFn();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitLoading(false);
  };

  const cancelFn = () => {
    if (submitLoading) {
      message.warning('Submitting, do not close＝');
      return;
    }
    setIsModalOpen(false);
    formRef.current.resetFields();
  };

  // 坯动
  const [startLoading, setStartLoading] = useState(false);
  const startFn = () => {
    const lastTime = window.localStorage.getItem('consumer_theater_project_id_start_time');
    if (lastTime && lastTime > Date.now() - 3600000) {
      message.warning(`The voxpopme task can be started only once an hour＝Last start： ${new Date(+lastTime)}`);
      return;
    }
    setStartLoading(true);
    celeryTask({
      'task_name': 'import_vpm'
    })
      .then(() => {
        message.success('The voxpopme task started successfully');
        window.localStorage.setItem('consumer_theater_project_id_start_time', Date.now());
        setTimeout(() => {
          setStartLoading(false);
        }, 3000);
      })
      .catch(error => {
        console.error(error);
        setStartLoading(false);
      });
  };

  return <div className={style.pageContainer}>
    <div className={style.pageHeader}>Project_ID</div>
    <div className={style.searchFlex}>
      <div className={style.searchBox}>
        <Search 
          allowClear
          prefix={<SearchOutlined />}
          placeholder="Enter a title to search" 
          enterButton="Search" 
          value={searchDefaultValue}
          onChange={e => searchChange(e.target.value)}
          onSearch={onSearch}
        />
      </div>
      <div className={style.toolBtns}>
        <Button className={style.btnLeft} type="primary" shape="round" icon={<PlusOutlined />} onClick={addFn}>Add</Button>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          loading={startLoading}
          onClick={startFn}
        />
      </div>
    </div>
    <div className={style.tableBox}>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={tableData}
        loading={{spinning: tableLoading, indicator: antIcon}}
        onChange={paginateChange}
        rowClassName={rowClassName}
        pagination={{
          position: ['bottomCenter'],
          showTotal: total => `Total ${total}`,
          showQuickJumper: true,
          current,
          pageSize,
          total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 40],
        }}
      />
    </div>
    <Modal 
      width={1000}
      title={modalTitle} 
      open={isModalOpen} 
      maskClosable={false}
      destroyOnClose
      cancelText="cancel"
      okText="confirm"
      onCancel={cancelFn}
      onOk={submitFn}
      okButtonProps={{
        loading: submitLoading
      }}
    >
      <div className={style.formBody}>
        <Form 
          ref={formRef}
          {...layout}
          onFinish={onFinish} 
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            name="project_id"
            label="project_id"
            rules={[
              {
                required: true,
                message: 'Missing project_id',
              },
            ]}
          >
            <InputNumber style={{width: '100%'}} controls={false} placeholder="project_id" />
          </Form.Item>
          <Form.Item
            name="title"
            label="title"
            rules={[
              {
                required: true,
                message: 'Missing title',
              },
            ]}
          >
            <Input placeholder="title" />
          </Form.Item>
          <Form.Item
            name="topic"
            label="topic"
          >
            <Input placeholder="topic" />
          </Form.Item>
          <Form.Item
            name="description"
            label="description"
          >
            <Input placeholder="description" />
          </Form.Item>
          <Form.Item
            name="country"
            label="country"
          >
            <Input placeholder="country" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  </div>;
};

export default ProjectIdPage;