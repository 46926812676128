import React from 'react';
import AnimatedWordCloud from './word-cloud-chart.jsx';
import Style from './word-cloud.module.scss';
import { aiTopDishInfo } from '../../../../components/icons/icons.jsx';
import Tippy from '@tippyjs/react';
import { Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'tippy.js/dist/tippy.css';

const WordCloud = ({ selectedWordCloud, wordCSelectedData, loading, err }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: '#00FF00',
      }}
      spin
    />
  );

  return (
    <div className={`${Style.wordCloudMainContainer}`}>
      <div className={`${Style.wordCloudContainer}`}>
        <div className={Style.mainContainer}>
          <div className={Style.wordTextContainer}>
            <div>
              <h2>
                {wordCSelectedData && wordCSelectedData.length > 0
                  ? `Wordcloud - ${wordCSelectedData[0]}`
                  : 'Wordcloud'}
              </h2>

            </div>
            <div className={Style.infoText}>
              <Tippy
                maxWidth="250px"
                theme="custom"
                content={
                  <span>
                    Trending topics that are mentioned the most in relation to the selected top dish.
                  </span>
                }
                placement="top"
              >
                <img src={aiTopDishInfo} alt="info" />
              </Tippy>
            </div>
          </div>
          <div className={Style.wordContainer}>
            {loading ? (
              <Spin indicator={antIcon}>
                <div className={Style.placeholder}></div>
              </Spin>
            ) : (
              <>
                {(selectedWordCloud && selectedWordCloud.length === 0)  || err === true ? (
                  <Empty
                    className={Style.emptyTable}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ) : (
                  <AnimatedWordCloud selectedWordCloud={selectedWordCloud} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordCloud;
