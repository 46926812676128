import { Checkbox } from 'antd';
import React, { useRef } from 'react';
import { download } from '../../../../components/icons/icons.jsx';
import style from './item.module.scss';

function PackageAiItem({ data, selected, onSelect }) {
  const aRef = useRef();

  function onDownload() {
    if (!aRef.current) return;
    aRef.current.click();
  }

  return (
    <div className={style.box}>
      <div className={style.boxInner}>
        <div className={style.img}>
          <img src={data.url} loading="lazy" />
        </div>
        <div className={style.body}>
          <h3>{data.title}</h3>
        </div>
      </div>
      <div className={style.btnDownload} onClick={onDownload}>
        <img src={download} />
      </div>
      <Checkbox
        className={style.btnCheck}
        checked={selected}
        onChange={onSelect}
      />
      <a ref={aRef} href={data.url} download style={{ display: 'none' }}></a>
    </div>
  );
}

export default PackageAiItem;
