// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ynw9U8xj83OboJKNG0P3{display:inline-block;padding:9px 80px;border-radius:9999px;cursor:pointer;background:#00a32e;font-size:20px;font-weight:900;line-height:27.88px;color:#fff}.F1wTnD4M_J8izxsRggsr{filter:grayscale(0.8);cursor:not-allowed}.jE_Rqw6Fj8BFp9WYpwaL{display:inline-block;padding:14px 50px;cursor:pointer;font-size:20px;font-weight:900;line-height:27.88px;color:#fff;background-size:100% 100%;background-repeat:no-repeat}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/components/btn.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,gBAAA,CACA,oBAAA,CACA,cAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,UAAA,CAGF,sBACE,qBAAA,CACA,kBAAA,CAGF,sBACE,oBAAA,CACA,iBAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CACA,UAAA,CACA,yBAAA,CACA,2BAAA","sourcesContent":[".btn1 {\n  display: inline-block;\n  padding: 9px 80px;\n  border-radius: 9999px;\n  cursor: pointer;\n  background: #00a32e;\n  font-size: 20px;\n  font-weight: 900;\n  line-height: 27.88px;\n  color: #fff;\n}\n\n.btnDisabled {\n  filter: grayscale(0.8);\n  cursor: not-allowed;\n}\n\n.btn2 {\n  display: inline-block;\n  padding: 14px 50px;\n  cursor: pointer;\n  font-size: 20px;\n  font-weight: 900;\n  line-height: 27.88px;\n  color: #fff;\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn1": `Ynw9U8xj83OboJKNG0P3`,
	"btnDisabled": `F1wTnD4M_J8izxsRggsr`,
	"btn2": `jE_Rqw6Fj8BFp9WYpwaL`
};
export default ___CSS_LOADER_EXPORT___;
