import { useEffect, useState } from 'react';
import { getPackageAiAdobeFireflyAccessToken } from '../apis/serve';
import { setToken as setTokenCache } from '../apis/adobe';

export function useAdobeFireflyToken() {
  const [token, setToken] = useState(null);

  async function getToken() {
    const { data } = await getPackageAiAdobeFireflyAccessToken();
    if (!data) {
      console.warn('get token fail', data);
      return;
    }
    setToken(data);
    setTokenCache(data.id, data.token);
  }

  useEffect(() => {
    void getToken();
  }, []);

  return { token, setToken };
}
