import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getProduct } from '../../api/index.js';
import Filter from '../../components/filter/index.jsx';
import Table from '../../components/table/table.jsx';
import { getVerticalOptionValue } from '../filter/vertical.js';
import style from './topline.module.scss';
import Tutorial from './tutorial.jsx';

const Topline = () => {
  // loading
  const [isLoading, setIsLoading] = useState(true);

  // sort
  const [sortKey, setSortKey] = useState('brand_logo_visibility');
  const [sort, setSort] = useState('desc');

  const changSort = (key) => {
    if (sortKey === key) {
      setSort(sort === 'desc' ? 'asc' : 'desc');
    } else {
      setSort('desc');
      setSortKey(key);
    }
  };

  // 本地排序
  const sortData = (array, key, order) => {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // count avg
  const countAvg = (data) => {
    let brand = 0;
    let name = 0;
    let appeal = 0;
    let intent = 0;
    let brandAvg = 0;
    let nameAvg = 0;
    let appealAvg = 0;
    let intentAvg = 0;
    if (data.length > 0) {
      for (const item of data) {
        brand += item.brand_logo_visibility;
        name += item.variant_name_visibility;
        appeal += item.appeal;
        intent += item.upi;
      }
      brandAvg = parseFloat((brand / data.length).toFixed(2));
      nameAvg = parseFloat((name / data.length).toFixed(2));
      appealAvg = parseFloat((appeal / data.length).toFixed(2));
      intentAvg = parseFloat((intent / data.length).toFixed(2));
    }
    setHeaderData((prev) => {
      return prev.map((item) => {
        if (item.key === 'brand_logo_visibility') {
          item.value = brandAvg;
        }
        if (item.key === 'variant_name_visibility') {
          item.value = nameAvg;
        }
        if (item.key === 'appeal') {
          item.value = appealAvg;
        }
        if (item.key === 'upi') {
          item.value = intentAvg;
        }
        return item;
      });
    });
  };

  // build data
  const buildData = (data) => {
    // avg
    countAvg(data);
    setBodyData([]);
    if (data.length > 0) {
      const tmpTableData = data.map((item) => {
        return {
          id: item.sn,
          name: item.display_name,
          market: item.market,
          value: [
            item.brand_logo_visibility,
            item.variant_name_visibility,
            item.appeal,
            item.upi,
          ],
        };
      });
      setBodyData(tmpTableData);
    }
  };

  // headerData
  const [headerData, setHeaderData] = useState([
    {
      title: 'Brand Logo Visibility',
      key: 'brand_logo_visibility',
      tips: 'This metric measures the probability of the brand logo being noticed in the first 3-5 seconds. It is based on 3M VAS algorithm.',
      value: 0,
      isSortDesc: true,
      isSortAsc: false,
    },
    {
      title: 'Variant Name Visibility',
      key: 'variant_name_visibility',
      tips: 'This metric evaluates the likelihood that the variant name will be noticed within the first 3-5 seconds of viewing. It utilizes the 3M VAS algorithm to provide this assessment.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Appeal',
      key: 'appeal',
      tips: 'This metric measures the percentage of respondents who find the packaging moderately and strongly appealing. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
    {
      title: 'Unpriced Purchase Intent',
      key: 'upi',
      tips: 'This metric measures the percentage of respondents who find the somewhat or very willing to purchase the product based on design alone. It is derived from consumer survey data.',
      value: 0,
      isSortDesc: false,
      isSortAsc: false,
    },
  ]);

  // bodyData
  const [bodyData, setBodyData] = useState([]);
  // const bodyData = [
  //   { id: 1, img:imgDemo1, name: 'Konrr Shellfish Fond Sauce 600g', value: [53,54,55,56] },
  // ];

  // filters
  const [filterData, setFilterData] = useState({});
  const postFilterData = useMemo(() => {
    if (!filterData.vertical) return filterData;
    const vl = [];
    for (const v of filterData.vertical) {
      vl.push(...getVerticalOptionValue(v));
    }
    if (!vl.length) return filterData;
    const cl = (filterData.category ?? []).concat(vl);
    const cm = new Map();
    for (const c of cl) {
      cm.set(c, 1);
    }
    const cll = Array.from(cm.keys());
    return {
      ...filterData,
      category: cll,
      vertical: undefined,
    };
  }, [filterData]);
  const [subFilterData, setSubFilterData] = useState({});

  // fetch data
  const [originData, setOriginData] = useState([]);
  const fetchData = useCallback(
    async (sort, order) => {
      setIsLoading(true);
      setOriginData([]);
      const { data } = await getProduct(
        postFilterData,
        subFilterData,
        undefined,
        sort,
        order
      );
      setOriginData(data);
      buildData(data);
      setIsLoading(false);
    },
    [postFilterData, subFilterData]
  );
  useEffect(() => {
    void fetchData();
  }, [postFilterData, subFilterData]);

  // sort
  useEffect(() => {
    const temp = [...originData];
    const newTemp = sortData(temp, sortKey, sort);
    setOriginData(newTemp);
    buildData(newTemp);
    setHeaderData((prev) => {
      return prev.map((item) => {
        if (item.key === sortKey) {
          item.isSortDesc = sort === 'desc';
          item.isSortAsc = sort === 'asc';
        } else {
          item.isSortDesc = false;
          item.isSortAsc = false;
        }
        return item;
      });
    });
  }, [sortKey, sort]);

  return (
    <div className={style.optWrap}>
      {/* Search */}
      <Filter
        showSearch={false}
        data={filterData}
        setData={setFilterData}
        showSubFilters={true}
        subData={subFilterData}
        setSubData={setSubFilterData}
      />
      {/* Table */}
      <div className={style.tblWrap}>
        <Table
          header={headerData}
          data={bodyData}
          onAvgClick={changSort}
          loading={isLoading}
          originData={originData}
          small={false}
        />
      </div>
      <Tutorial />
    </div>
  );
};

export default Topline;
