// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aPLj_LUBPBnaUE2_mPi6{height:100%;display:flex;justify-content:space-between}.gKXZtx7kpcBdE4Vz7NDT{width:68%;height:100%}.i16gA0ij9H1Ua60EghKQ{width:30%;height:100%}.V_4DGRT3lOcxsVsvm2DQ{height:100%;padding:16px 36px}.V_4DGRT3lOcxsVsvm2DQ .ant-radio-input{opacity:0 !important}.GduiWC7xSUVbiGvqNrqn{position:absolute;bottom:12px;left:12px;font-size:10px;font-weight:400;line-height:13px;color:#fff;opacity:.4}.QZK4An6ti6GoOWYtCRfz{font-size:12px;font-weight:400;line-height:16.73px;color:rgba(255,255,255,.5019607843)}.BHWIk3TJk0jofEgWXcIF{display:flex;justify-content:center;margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/edit/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CAGF,sBACE,SAAA,CACA,WAAA,CAGF,sBACE,SAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CAEE,uCACE,oBAAA,CAKN,sBACE,iBAAA,CACA,WAAA,CACA,SAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,mBAAA,CACA,mCAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".box {\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.box1 {\n  width: 68%;\n  height: 100%;\n}\n\n.box2 {\n  width: 30%;\n  height: 100%;\n}\n\n.box2Content {\n  height: 100%;\n  padding: 16px 36px;\n  :global {\n    .ant-radio-input {\n      opacity: 0 !important;\n    }\n  }\n}\n\n.info1 {\n  position: absolute;\n  bottom: 12px;\n  left: 12px;\n  font-size: 10px;\n  font-weight: 400;\n  line-height: 13px;\n  color: #fff;\n  opacity: 0.4;\n}\n\n.label {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16.73px;\n  color: #ffffff80;\n}\n\n.btnLine {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `aPLj_LUBPBnaUE2_mPi6`,
	"box1": `gKXZtx7kpcBdE4Vz7NDT`,
	"box2": `i16gA0ij9H1Ua60EghKQ`,
	"box2Content": `V_4DGRT3lOcxsVsvm2DQ`,
	"info1": `GduiWC7xSUVbiGvqNrqn`,
	"label": `QZK4An6ti6GoOWYtCRfz`,
	"btnLine": `BHWIk3TJk0jofEgWXcIF`
};
export default ___CSS_LOADER_EXPORT___;
