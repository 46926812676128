import React, { useEffect, useMemo, useRef } from 'react';
import {
  Image as KonvaImage,
  Text as KonvaText,
  Layer,
  Stage,
  Transformer,
} from 'react-konva';
import useImage from 'use-image';
import style from './editor.module.scss';

function PackAiEditorImage({
  index,
  item,
  onDragStart,
  onDragMove,
  onDragEnd,
  setItem,
  selectItem,
}) {
  const [image] = useImage(item.url);
  const imageRef = useRef();
  const transformerRef = useRef();

  // init transformer
  useEffect(() => {
    if (!item.selected) return;
    if (!imageRef.current || !transformerRef.current) return;
    transformerRef.current.nodes([imageRef.current]);
    transformerRef.current.getLayer().batchDraw();
  }, [item.selected, image]);

  const x = useMemo(() => item.x, [item.x]);
  const y = useMemo(() => item.y, [item.y]);
  const height = useMemo(
    () => item.height ?? image?.height ?? 50,
    [item.height, image]
  );
  const width = useMemo(
    () => item.width ?? image?.width ?? 50,
    [item.width, image]
  );

  function onSelect(ev) {
    ev.cancelBubble = true;
    selectItem(index);
  }

  function onTransformEnd() {
    const node = imageRef.current;
    if (!node) return;
    setItem(index, {
      ...item,
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    });
  }

  if (!image) return null;
  return (
    <>
      <KonvaImage
        image={image}
        ref={imageRef}
        x={x}
        y={y}
        width={width}
        height={height}
        onClick={onSelect}
        draggable={true}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransformEnd={onTransformEnd}
      />
      {item.selected && (
        <Transformer
          ref={transformerRef}
          resizeEnabled={true}
          rotateEnabled={false}
          enabledAnchors={[
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ]}
        ></Transformer>
      )}
    </>
  );
}

function PackageAiText({
  index,
  item,
  onDragStart,
  onDragMove,
  onDragEnd,
  selectItem,
}) {
  function onSelect(ev) {
    ev.cancelBubble = true;
    selectItem(index);
  }
  return (
    <KonvaText
      text={item.text}
      x={item.x}
      y={item.y}
      fontSize={item.fontSize}
      onClick={onSelect}
      draggable={true}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
    />
  );
}

function PackAiEditorItem({ item, index, setItem, selectItem }) {
  function onDragStart() {
    setItem(index, { ...item, isDragging: true });
  }
  function onDragEnd(e) {
    setItem(index, {
      ...item,
      isDragging: false,
      x: e.target.getX(),
      y: e.target.getY(),
    });
  }

  if (item.type === 'image') {
    return (
      <PackAiEditorImage
        key={index}
        index={index}
        item={item}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        setItem={setItem}
        selectItem={selectItem}
      ></PackAiEditorImage>
    );
  } else if (item.type === 'text') {
    return (
      <PackageAiText
        key={index}
        index={index}
        item={item}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        selectItem={selectItem}
      />
    );
  } else {
    return null;
  }
}

function PackageAiEditor({ url, data, setData, setDrawRef }) {
  const [image] = useImage(url);
  const boxRef = useRef(null);
  const contain = useMemo(() => {
    if (!image) return;
    if (!boxRef.current) return;
    const s = boxRef.current.clientHeight / image.height;
    return { width: image.width * s, posScale: s };
  }, [image, boxRef]);

  function setItem(index, item) {
    const newData = [...data];
    newData[index] = item;
    setData(newData);
  }
  function selectItem(index) {
    const newData = [...data];
    newData.forEach((e) => (e.selected = false));
    newData[index].selected = true;
    setData(newData);
  }
  function deleteItem(index) {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  }
  function onMouseLeave() {
    const newData = [...data];
    newData.forEach((e) => (e.selected = false));
    setData(newData);
  }

  const deleteBtn = useMemo(() => {
    const index = data.findIndex((e) => e.selected);
    if (index === -1) return null;
    const item = data[index];
    console.log(item);
    return (
      <button
        className={style.editorDeleteBtn}
        style={{
          top: `${item.y - 10}px`,
          left: `${item.x}px`,
        }}
        onClick={() => deleteItem(index)}
      >
        Delete
      </button>
    );
  }, [data]);

  const drawRef = useRef(null);
  useEffect(() => {
    setDrawRef(drawRef.current);
  }, drawRef.current);

  return (
    <div className={style.editorBox} ref={boxRef} onMouseLeave={onMouseLeave}>
      <div className={style.editorInner}>
        <Stage width={contain?.width} height={boxRef.current?.clientHeight}>
          <Layer>
            {image && (
              <KonvaImage
                image={image}
                width={contain?.width}
                height={boxRef.current?.clientHeight}
              />
            )}
          </Layer>
          <Layer ref={drawRef}>
            {data.map((e, i) => (
              <PackAiEditorItem
                key={i}
                item={e}
                index={i}
                setItem={setItem}
                selectItem={selectItem}
              />
            ))}
          </Layer>
        </Stage>
        {deleteBtn}
      </div>
    </div>
  );
}

export default PackageAiEditor;
