// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D1QtGZpKxA2CoPENTzJT{height:100%;padding:30px;box-sizing:border-box}.WJBuKTKP1puxMjvPrz2U{margin-top:20px;display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-generation/views/update/text.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":[".textBox {\n  height: 100%;\n  padding: 30px;\n  box-sizing: border-box;\n}\n\n.textBtn {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBox": `D1QtGZpKxA2CoPENTzJT`,
	"textBtn": `WJBuKTKP1puxMjvPrz2U`
};
export default ___CSS_LOADER_EXPORT___;
