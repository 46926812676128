import React from 'react';
import BtnPng from '../assets/img/btn.png';
import style from './btn.module.scss';

export function PackageAiBtn1({
  className,
  children,
  onClick,
  disabled = false,
}) {
  function handleClick(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn1} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

export function PackageAiBtn2({
  className,
  children,
  onClick,
  disabled = false,
}) {
  function handleClick(ev) {
    if (disabled) return;
    onClick && onClick(ev);
  }

  return (
    <div
      className={`${style.btn2} ${
        disabled ? style.btnDisabled : ''
      } ${className}`}
      style={{ backgroundImage: `url(${BtnPng})` }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}
