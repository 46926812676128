import { App, ConfigProvider, message, theme } from 'antd';
import React, { useState } from 'react';
import { postGenerativeFill, postUploadImage } from '../../apis/adobe/index.js';
import Tab from '../../components/box-tab.jsx';
import Box from '../../components/box.jsx';
import { PackageAiBtn2 as Btn } from '../../components/btn.jsx';
import Container from '../../components/container.jsx';
import { useAdobeFireflyToken } from '../../utils/useAdobeFireflyToken.js';
import Editor from './editor.jsx';
import style from './index.module.scss';
import Library from './library.jsx';
import Text from './text.jsx';
import Upload from './upload.jsx';

function PackageAiView() {
  const context = JSON.parse(
    sessionStorage.getItem('packageAiContext') ?? '{}'
  );
  const url = context.edit.imageUrl;
  const id = context.edit.imageId;

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('library');
  const [data, setData] = useState([]);
  const [drawRef, setDrawRef] = useState(null);
  useAdobeFireflyToken();

  function addImage(url) {
    setData([...data, { type: 'image', url, x: 0, y: 0 }]);
  }

  function addText(text, size) {
    setData([...data, { type: 'text', text, x: 0, y: 0, fontSize: size }]);
  }

  async function onRender() {
    if (!drawRef) return;
    if (loading) return;
    setLoading(true);
    try {
      const drawUrl = drawRef.toDataURL();
      const blob = await fetch(drawUrl).then((res) => res.blob());
      const { status: s1, data: d1 } = await postUploadImage(
        blob.arrayBuffer,
        blob.type
      );
      if (s1 !== 200) {
        message.error('Upload mask fail');
        console.error('upload mask fail', s1, d1);
        return;
      }
      if (!d1.images?.length) {
        message.error('Upload mask fail');
        console.error('upload mask fail', d1);
        return;
      }
      const { status: s2, data: d2 } = await postGenerativeFill({
        prompt: prompt,
        size: 'square',
        image: { id: id },
        mask: { id: d1.images[0].id },
      });
      if (s2 !== 200) {
        message.error('Generate image fail');
        console.error('generate image fail', s2, d2);
        return;
      }
      if (!d2.images?.length) {
        message.error('Generate image fail');
        console.error('generate image fail', d2);
        return;
      }
      sessionStorage.setItem(
        'packageAiContext',
        JSON.stringify({
          ...context,
          update: {
            data,
            maskId: d1.images[0].id,
            imageId: d2.images[0].image.id,
            imageUrl: d2.images[0].image.presignedUrl,
          },
        })
      );
      window.location.href = '/package-ai-generation4';
    } catch (err) {
      console.error('generate image error', err);
      message.error('Generate image error');
    }
    setLoading(false);
  }

  if (!url || !id) {
    message.error('Please select a package first');
    window.location.href = '/package-ai-generation';
    return;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App>
        <Container step={3}>
          <div className={style.box}>
            <Box className={style.box1} loading={loading}>
              <Editor
                url={url}
                data={data}
                setData={setData}
                setDrawRef={setDrawRef}
              ></Editor>
              <div className={style.info1}>
                * Due to privacy legislation all insights & photos / videos can
                only be used internally,
                <br />
                and only after verbal consent from the audience. Ideally, no
                personal data is
                <br />
                obtained and any personal data obtained anonymized.*
              </div>
              <Btn className={style.btn1} onClick={onRender}>
                Render Immediately
              </Btn>
            </Box>
            <Tab
              className={style.box2}
              active={active}
              setActive={setActive}
              options={[
                {
                  label: 'Library',
                  value: 'library',
                  children: <Library addImage={addImage} />,
                },
                {
                  label: 'Text',
                  value: 'text',
                  children: <Text addText={addText} />,
                },
              ]}
              headerRight={active === 'library' && <Upload></Upload>}
            ></Tab>
          </div>
        </Container>
      </App>
    </ConfigProvider>
  );
}

export default PackageAiView;
